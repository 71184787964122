import React, { useContext } from "react";

//Style
import Styles from "./LoadDetailsSection.module.scss";

//Component
import Typography from "@vahak/core-ui/dist/components/Typography";
import UserNameWithBadge from "../../../../user-name-with-badge/UserNameWithBadge";
import SourceDestination from "../../../../posting-form/common/src-dest/SourceDestination";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import LoadLorryQuickInfo from "../../../../posting-form/common/load-lorry-quick-info/LoadLorryQuickInfo";
import { IDs } from "@vahak/core/dist/constants/automation";
import {
    LORRY_BODY_TYPE_MAP,
    LORRY_CAPACITY_UNIT_TYPES,
    LORRY_CATEGORIES_ENUM
} from "@vahak/core/dist/constants/lorryTypes";

import LineDivider from "@vahak/core-ui/dist/components/LineDivider";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import LoadQuickInfo from "../../../../posting-form/common/load-quick-info/LoadQuickInfo";
import { LoadMarketCard } from "../../../../../services/load/useGetLoadMarketV2/type";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
import { BiddingFormContext } from "../../../../../BiddingFormContextProvider";
import VahakAssuredIcon from "@vahak/core/dist/icons/vahakAssured.svg";
import { getJoinedStringFromArray } from "@vahak/core-ui/dist/methods";

interface LoadDetailsSectionProps {
    loadData: LoadMarketCard;
}

const LoadDetailsSection = ({ loadData }: LoadDetailsSectionProps) => {
    const { company_detail, load_detail } = loadData;

    const { dispatchBid } = useContext(BiddingFormContext);

    const isMobileScreen = useMediaQuery({ queryType: "mobile" });

    return (
        <div className={Styles.main}>
            <div className={Styles.label}>
                <Typography size="l" weight="semibold">
                    Load details
                </Typography>
                {(loadData?.load_detail?.is_tap_n_go || loadData?.load_detail?.is_spot_load) && <VahakAssuredIcon />}
            </div>

            <Flex className={Styles.userInfoWrapper} gap={isMobileScreen ? 16 : 32} flexDirection="column">
                {!isMobileScreen ? (
                    <UserNameWithBadge
                        companyLogo={company_detail.logo}
                        name={company_detail.name}
                        isBankVerified={company_detail.is_bank_verified}
                        isVerified={company_detail.is_company_verified}
                        isAadhaarVerified={loadData.company_detail.is_aadhaar_verified}
                        isGstVerified={loadData.company_detail.is_gst_verified}
                        isPanVerified={loadData.company_detail.is_company_verified}
                        isMember={loadData.company_detail.is_member}
                        rating={company_detail.ratings}
                        companyNameId={IDs.bidForm.bidForm_companyName}
                    />
                ) : (
                    <></>
                )}

                <Flex flexDirection="column" gap={isMobileScreen ? 16 : 20}>
                    <SourceDestination
                        sourceCity={getJoinedStringFromArray(
                            [load_detail.source_locality_name, load_detail.source_city_name],
                            ", "
                        )}
                        sourceState={load_detail.source_state_name}
                        destCity={getJoinedStringFromArray(
                            [load_detail.source_locality_name, load_detail.destination_city_name],
                            ", "
                        )}
                        destState={load_detail.destination_state_name}
                        ellipses
                    />

                    <LineDivider
                        styleType="solid"
                        color={COLORS.GREY_200}
                        borderWidth={2}
                        margin="-18px 0 0 0"
                        width={isMobileScreen ? "120px" : "auto"}
                    />

                    <Flex flexDirection="column">
                        <LoadLorryQuickInfo
                            capacity={load_detail.quantity}
                            capacityType={
                                load_detail.lorry_type_id === LORRY_CATEGORIES_ENUM.TANKER
                                    ? LORRY_CAPACITY_UNIT_TYPES.KILO_LITRES
                                    : LORRY_CAPACITY_UNIT_TYPES.TONNES
                            }
                            material={load_detail.material_type}
                            type="load"
                            fontWeight="medium"
                        />

                        <LoadLorryQuickInfo
                            lorryType={load_detail.lorry_type_id}
                            type="lorry"
                            tyres={load_detail.total_tyres}
                            fontWeight="medium"
                            bodyType={LORRY_BODY_TYPE_MAP[load_detail.body_type]}
                            lorrySize={load_detail.size}
                            lorrySizeUnit={load_detail.size_text}
                        />
                    </Flex>

                    {(!!load_detail.distance ||
                        !!(load_detail.length && load_detail.breadth && load_detail.height)) && (
                        <>
                            <LineDivider
                                styleType="solid"
                                color={COLORS.GREY_200}
                                borderWidth={2}
                                width={isMobileScreen ? "120px" : "auto"}
                            />

                            <Flex flexDirection="column">
                                {!!load_detail.distance ? (
                                    <LoadQuickInfo
                                        type="payment"
                                        averageKM={load_detail.distance}
                                        paymentType={load_detail.payment_type}
                                        fontWeight="medium"
                                        advance={load_detail.advance_payment_percentage}
                                    />
                                ) : (
                                    <></>
                                )}

                                {!!(load_detail.length && load_detail.breadth && load_detail.height) ? (
                                    <LoadQuickInfo
                                        type="size"
                                        length={load_detail.length}
                                        breadth={load_detail.breadth}
                                        height={load_detail.height}
                                        fontWeight="medium"
                                    />
                                ) : (
                                    <></>
                                )}
                            </Flex>
                        </>
                    )}
                </Flex>

                {loadData.load_detail.remarks ? (
                    <>
                        {isMobileScreen ? (
                            <LineDivider styleType="solid" color={COLORS.GREY_200} borderWidth={2} width="120px" />
                        ) : (
                            <></>
                        )}

                        <Typography weight="medium" size="sm" lineHeight={1.2} mSize="s">
                            {loadData.load_detail.remarks}
                        </Typography>
                    </>
                ) : (
                    <></>
                )}

                {load_detail.expected_amount ? (
                    <>
                        {isMobileScreen ? (
                            <LineDivider styleType="solid" color={COLORS.GREY_200} borderWidth={2} width="120px" />
                        ) : (
                            <></>
                        )}
                        <Typography weight="semibold" size="m">
                            Loader Price:{" "}
                            <Typography weight="semibold" size="m" color={COLORS.GREEN_600}>
                                ₹{Intl.NumberFormat("en-IN").format(load_detail.expected_amount)}
                            </Typography>{" "}
                            (for {load_detail.quantity} tonnes)
                        </Typography>
                    </>
                ) : (
                    <></>
                )}
            </Flex>
        </div>
    );
};

export default LoadDetailsSection;
