import { ScreenId } from "@vahak/core/dist/constants/admin/screenId";
import { generateSelectOption } from "../../../methods/uiUtil";

export enum TicketType {
    EWAY_VERIFICATION = 5,
    CALL_ME_BACK = 10,
    HELP = 11,
    VAS_LEADS_CARGO = 12,
    VAS_LEADS_GPS = 13,
    ACCOUNT_DELETION = 14,
    ASSURED_CALLBACK = 15,
    VAS_LEADS_VEHICLE_INSURANCE = 16,
    VAS_LEADS_BUSINESS_LOAN = 17,
    IN_TRANSIT_CALLBACK = 19,
    COMPANY_REGISTRATION = 24,
    AADHAAR_VERIFICATION = 100,
    BUSINESS_VERIFICATION = 200,
    PAN_VERIFICATION = 300,
    RC_VERIFICATION_BLOCKED = 400,
    RC_VERIFICATION_FAILED = 401,
    REFUND_REQUEST = 500,
    BID_CANCELLATION = 510,
    RC_VERIFICATION_FITNESS = 604,
    RC_VERIFICATION_TDS = 605
}
export const TicketTypeToNameMap: Partial<Record<TicketType, string>> = {
    [TicketType.RC_VERIFICATION_BLOCKED]: "Lorry Document",
    [TicketType.RC_VERIFICATION_FAILED]: "Lorry Document",
    [TicketType.RC_VERIFICATION_TDS]: "Lorry TDS Document",
    [TicketType.RC_VERIFICATION_FITNESS]: "Lorry Fitness Document"
};
export enum VasType {
    CARGO = 1,
    GPS,
    VEHICLE_INSURANCE,
    BUSINESS_LOAN
}

export enum TicketSystemTypes {
    EWAY_VERIFICATION = "eway",
    RC_VERIFICATION = "rc",
    AADHAAR_VERIFICATION = "aadhaar",
    PAN_VERIFICATION = "pan",
    BUSINESS_VERIFICATION = "business",
    REFUND_REQUEST = "refund-request",
    CALL_ME_BACK = "call-me-back",
    HELP = "help",
    ACCOUNT_DELETION = "account-deletion",
    ASSURED_CALLBACK = "assured-callback",
    VAS_LEADS = "vas-leads",
    BID_CANCELLATION = "bid-cancellation",
    COMPANY_REGISTRATION_LEADS = "company-registration-leads"
}

export const TicketTypeMap = {
    [TicketSystemTypes.EWAY_VERIFICATION]: TicketType.EWAY_VERIFICATION,
    [TicketSystemTypes.CALL_ME_BACK]: TicketType.CALL_ME_BACK,
    [TicketSystemTypes.AADHAAR_VERIFICATION]: TicketType.AADHAAR_VERIFICATION,
    [TicketSystemTypes.BUSINESS_VERIFICATION]: TicketType.BUSINESS_VERIFICATION,
    [TicketSystemTypes.PAN_VERIFICATION]: TicketType.PAN_VERIFICATION,
    [TicketSystemTypes.RC_VERIFICATION]: undefined,
    [TicketSystemTypes.REFUND_REQUEST]: TicketType.REFUND_REQUEST,
    [TicketSystemTypes.VAS_LEADS]: undefined,
    [TicketSystemTypes.BID_CANCELLATION]: TicketType.BID_CANCELLATION,
    [TicketSystemTypes.HELP]: TicketType.HELP,
    [TicketSystemTypes.ACCOUNT_DELETION]: TicketType.ACCOUNT_DELETION,
    [TicketSystemTypes.ASSURED_CALLBACK]: TicketType.ASSURED_CALLBACK,
    [TicketSystemTypes.COMPANY_REGISTRATION_LEADS]: TicketType.COMPANY_REGISTRATION
};

export enum TicketSystemDocActionType {
    REJECT = "reject",
    ACCEPT = "accept",
    PHYSICAL_VERIFICATION = "physical verification"
}

export const TicketTitle = {
    [TicketSystemTypes.AADHAAR_VERIFICATION]: "Aadhaar Verification",
    [TicketSystemTypes.RC_VERIFICATION]: "RC Verification",
    [TicketSystemTypes.PAN_VERIFICATION]: "PAN Verification",
    [TicketSystemTypes.BUSINESS_VERIFICATION]: "Business Document Verification",
    [TicketSystemTypes.EWAY_VERIFICATION]: "E-way Bill Verification",
    [TicketSystemTypes.REFUND_REQUEST]: "Refund Request",
    [TicketSystemTypes.CALL_ME_BACK]: "Call me back",
    [TicketSystemTypes.VAS_LEADS]: "VAS Leads",
    [TicketSystemTypes.BID_CANCELLATION]: "Cancellation requests",
    [TicketSystemTypes.HELP]: "Help tickets",
    [TicketSystemTypes.ACCOUNT_DELETION]: "Account deletion",
    [TicketSystemTypes.ASSURED_CALLBACK]: "Assured load call tickets",
    [TicketSystemTypes.COMPANY_REGISTRATION_LEADS]: "Company registration leads"
};
export const ExtendedTicketTitle: Partial<Record<TicketType, string>> = {
    [TicketType.RC_VERIFICATION_BLOCKED]: "RC Verification",
    [TicketType.RC_VERIFICATION_FAILED]: "RC Verification",
    [TicketType.RC_VERIFICATION_FITNESS]: "Lorry Fitness Verification",
    [TicketType.RC_VERIFICATION_TDS]: "TDS Verification"
};

export const TicketSystemRejectionComments = {
    [TicketSystemTypes.AADHAAR_VERIFICATION]: [
        "Image not clear",
        "Image not in proper portion",
        "Image on aadhaar not matching with selfie",
        "Already linked to another account",
        "Selfie image missing"
    ],
    [TicketSystemTypes.PAN_VERIFICATION]: [
        "Name on aadhaar not matching with PAN",
        "Incorrect PAN number",
        "PAN missing",
        "Image not clear"
    ],
    [TicketSystemTypes.BUSINESS_VERIFICATION]: [
        "Document not matching",
        "Already linked to another account",
        "Improper image format",
        "Document Expired"
    ],
    [TicketSystemTypes.RC_VERIFICATION]: [
        "Image not clear",
        "RC number does not match",
        "Upload fitness certificate",
        "RC Missing"
    ],
    [TicketSystemTypes.EWAY_VERIFICATION]: [
        "Invalid E way number",
        "Image not clear",
        "Not an E-way bill",
        "Repeat E-way bill",
        "E-way bill expired",
        "E-way bill date doesn't match",
        "E-way bill GST Doesn't match",
        "Not completed payment",
        "Vehicle number not matching"
    ],
    [TicketSystemTypes.REFUND_REQUEST]: [],
    [TicketSystemTypes.CALL_ME_BACK]: [],
    [TicketSystemTypes.VAS_LEADS]: [],
    [TicketSystemTypes.BID_CANCELLATION]: [],
    [TicketSystemTypes.HELP]: [],
    [TicketSystemTypes.ACCOUNT_DELETION]: [],
    [TicketSystemTypes.ASSURED_CALLBACK]: [],
    [TicketSystemTypes.COMPANY_REGISTRATION_LEADS]: []
};

export const RejectionCommentsByTicketType: Partial<Record<TicketType, string[]>> = {
    [TicketType.RC_VERIFICATION_BLOCKED]: TicketSystemRejectionComments[TicketSystemTypes["RC_VERIFICATION"]],
    [TicketType.RC_VERIFICATION_FAILED]: TicketSystemRejectionComments[TicketSystemTypes["RC_VERIFICATION"]],
    [TicketType.RC_VERIFICATION_TDS]: [
        "Image not clear",
        "RC number does not match",
        "Upload Tax Deduction Source document"
    ],
    [TicketType.RC_VERIFICATION_FITNESS]: [
        "Image not clear",
        "RC number does not match",
        "Upload fitness certificate",
        "Fitness certificate is expired "
    ]
};

export const FOSRejectionComment = ["Document not valid", "User not available", "Fraud user"];

export const AcceptAadhaarFormInputLabel = {
    aadhaarNumber: "Enter last 4-digit of Aadhaar number",
    aadhaarName: "Enter Name on Aadhaar",
    state: "Enter State"
};

export const AcceptEwayFormInputLabel = {
    ewayNumber: "E-way number"
};

export const AcceptGstFormInputLabel = {
    gstType: "Enter document type",
    legalName: "Enter legal name",
    gstNumber: "Enter document number"
};

export const TicketSystemModalTitle = {
    rejection: "Rejection comments",
    acceptAadhaar: "Accept Aadhaar document",
    acceptBusiness: "Accept Business document",
    addComments: "Your Comments",
    acceptPAN: "Accept PAN",
    acceptEWay: "Accept E-Way",
    rcVerification: "Accept RC"
};

export const TicketActionSuccessText: {
    [key in TicketSystemTypes]?: {
        [key in TicketSystemDocActionType]?: string;
    };
} = {
    [TicketSystemTypes.AADHAAR_VERIFICATION]: {
        [TicketSystemDocActionType.REJECT]: "Aadhaar Document Rejected Successfully",
        [TicketSystemDocActionType.ACCEPT]: "Aadhaar Document Accepted Successfully"
    },
    [TicketSystemTypes.PAN_VERIFICATION]: {
        [TicketSystemDocActionType.REJECT]: "PAN Document Rejected Successfully",
        [TicketSystemDocActionType.ACCEPT]: "PAN Document Accepted Successfully"
    },
    [TicketSystemTypes.BUSINESS_VERIFICATION]: {
        [TicketSystemDocActionType.REJECT]: "Business Document Rejected Successfully",
        [TicketSystemDocActionType.ACCEPT]: "Business Document Accepted Successfully",
        [TicketSystemDocActionType.PHYSICAL_VERIFICATION]: "Physical Verification Required Pending"
    },
    [TicketSystemTypes.RC_VERIFICATION]: {
        [TicketSystemDocActionType.REJECT]: "Document Rejected Successfully",
        [TicketSystemDocActionType.ACCEPT]: "Document Accepted Successfully"
    },
    [TicketSystemTypes.EWAY_VERIFICATION]: {
        [TicketSystemDocActionType.REJECT]: "E-way Bill Rejected Successfully",
        [TicketSystemDocActionType.ACCEPT]: "E-way Bill Accepted Successfully"
    }
};

export enum TicketStatusMap {
    Unverified = 0,
    Verified = 1,
    PhysicalVerification = 3,
    FOSVerification = 5,
    Rejected = 999
}

export const KycStatusMap = {
    [TicketStatusMap.Unverified]: "Unverified",
    [TicketStatusMap.Verified]: "Verified",
    [TicketStatusMap.PhysicalVerification]: "Physical Verification",
    [TicketStatusMap.FOSVerification]: "FOS Verified", // Not Using
    [TicketStatusMap.Rejected]: "Rejected"
};

export const CallMeBackStatusMap = {
    [TicketStatusMap.Unverified]: "New",
    [TicketStatusMap.Verified]: "Closed",
    [TicketStatusMap.PhysicalVerification]: "", // Not Using
    [TicketStatusMap.FOSVerification]: "", // Not Using
    [TicketStatusMap.Rejected]: "" // Not Using
};

export const RefundRequestStatusMap = {
    [TicketStatusMap.Unverified]: "Pending",
    [TicketStatusMap.Verified]: "Approved",
    [TicketStatusMap.Rejected]: "Rejected",
    [TicketStatusMap.PhysicalVerification]: "", // Not Using
    [TicketStatusMap.FOSVerification]: "" // Not Using
};

export enum TicketStatusText {
    ALL = "All",
    PENDING = "Pending",
    REJECTED = "Rejected",
    ACCEPTED = "Accepted",
    PHYSICAL_VERIFICATION = "Physical Verification",
    FOS_VERIFIED = "FOS Verified"
}

export enum TicketDocumentName {
    LR_DOC = "lrDoc",
    EWAY_DOC = "ewayDoc",
    GOODS_DOC = "goodsDoc",
    POD_DOC = "podDoc",
    RC_DOC = "rcDoc",
    LORRY_FITNESS_DOC = "lorryFitnessDoc",
    TDS_DOC = "tdsDoc",
    VEHICLE_INSURANCE_DOC = "vehicleInsuranceDoc",
    VEHICLE_OWNER_AADHAAR_DOC = "vehicleOwnerAadhaarDoc",
    DRIVER_LICENSE_DOC = "driverLicenseDoc",
    DRIVER_AADHAAR_DOC = "driverAadhaarDoc",
    SHOP_IMAGE_DOC = "shopImageDoc",

    //TYPESCRIPT
    AADHAAR_FRONT = "aadhaarFront",
    AADHAAR_BACK = "aadhaarBack",
    AADHAAR_SELFIE = "aadhaarSelfie",
    GST_CERTIFICATE = "gstCertificate",
    MSME_CERTIFICATE = "msmeCertificate",
    GUMASTA_LICENSE = "gumastaLicense",
    TRADE_LICENSE = "tradeLicense",
    RC_VALIDATION = "rcValidation",
    SUPPORTING_DOC = "supportDoc"
}

export enum TicketDocument {
    LORRY_RECEIPT = 1,
    POD = 2,
    SUPPORTING_DOC = 3,
    EWAY_BILL = 5,
    GOODS_INVOICE = 6,
    SHOP_IMAGE = 7,
    AADHAAR_FRONT = 100,
    AADHAAR_BACK = 101,
    AADHAAR_SELFIE = 102,
    GST_CERTIFICATE = 200,
    MSME_CERTIFICATE = 201,
    GUMASTA_LICENSE = 202,
    TRADE_LICENSE = 203,
    // PAN = 300,
    RC_VALIDATION = 400,
    RC_DOCUMENT = 401,
    DRIVER_LICENSE = 600,
    VEHICLE_INSURANCE = 601,
    DRIVER_AADHAAR = 602,
    VEHICLE_OWNER_AADHAAR = 603,
    LORRY_FITNESS = 604,
    TDS = 605
}
export const TICKET_TYPE_NAME_MAP = {
    [TicketDocument.LORRY_RECEIPT]: TicketDocumentName.LR_DOC,
    [TicketDocument.POD]: TicketDocumentName.POD_DOC,
    [TicketDocument.EWAY_BILL]: TicketDocumentName.EWAY_DOC,
    [TicketDocument.GOODS_INVOICE]: TicketDocumentName.GOODS_DOC,
    [TicketDocument.SHOP_IMAGE]: TicketDocumentName.SHOP_IMAGE_DOC,
    [TicketDocument.RC_DOCUMENT]: TicketDocumentName.RC_DOC,
    [TicketDocument.LORRY_FITNESS]: TicketDocumentName.LORRY_FITNESS_DOC,
    [TicketDocument.TDS]: TicketDocumentName.TDS_DOC,
    [TicketDocument.VEHICLE_INSURANCE]: TicketDocumentName.VEHICLE_INSURANCE_DOC,
    [TicketDocument.VEHICLE_OWNER_AADHAAR]: TicketDocumentName.VEHICLE_OWNER_AADHAAR_DOC,
    [TicketDocument.DRIVER_LICENSE]: TicketDocumentName.DRIVER_LICENSE_DOC,
    [TicketDocument.DRIVER_AADHAAR]: TicketDocumentName.DRIVER_AADHAAR_DOC,
    [TicketDocument.RC_VALIDATION]: TicketDocumentName.RC_DOC,

    //TYPESCRIPT - to avoid error
    [TicketDocument.AADHAAR_FRONT]: TicketDocumentName.DRIVER_AADHAAR_DOC,
    [TicketDocument.AADHAAR_BACK]: TicketDocumentName.DRIVER_AADHAAR_DOC,
    [TicketDocument.AADHAAR_SELFIE]: TicketDocumentName.DRIVER_AADHAAR_DOC,
    [TicketDocument.GST_CERTIFICATE]: TicketDocumentName.DRIVER_AADHAAR_DOC,
    [TicketDocument.MSME_CERTIFICATE]: TicketDocumentName.DRIVER_AADHAAR_DOC,
    [TicketDocument.GUMASTA_LICENSE]: TicketDocumentName.DRIVER_AADHAAR_DOC,
    [TicketDocument.TRADE_LICENSE]: TicketDocumentName.DRIVER_AADHAAR_DOC,
    [TicketDocument.SUPPORTING_DOC]: TicketDocumentName.SUPPORTING_DOC
};

export const TicketDocumentText: { [key in TicketDocument]: string } = {
    [TicketDocument.SUPPORTING_DOC]: "Supporting Documents",
    [TicketDocument.EWAY_BILL]: "Eway Bill",
    [TicketDocument.SHOP_IMAGE]: "Shop Image",

    [TicketDocument.AADHAAR_FRONT]: "Front image of aadhaar",
    [TicketDocument.AADHAAR_BACK]: "Back image of aadhaar",
    [TicketDocument.AADHAAR_SELFIE]: "Selfie",

    [TicketDocument.GST_CERTIFICATE]: "GST Certificate",
    [TicketDocument.MSME_CERTIFICATE]: "MSME Certificate",
    [TicketDocument.GUMASTA_LICENSE]: "Gumasta License",
    [TicketDocument.TRADE_LICENSE]: "Trade License",
    [TicketDocument.RC_VALIDATION]: "RC Validation",
    [TicketDocument.POD]: "POD",
    [TicketDocument.RC_DOCUMENT]: "RC Document",
    [TicketDocument.LORRY_FITNESS]: "Lorry Fitness",
    [TicketDocument.TDS]: "TDS",
    [TicketDocument.DRIVER_LICENSE]: "Driver License",
    [TicketDocument.VEHICLE_INSURANCE]: "Vehicle Insurance",
    [TicketDocument.DRIVER_AADHAAR]: "Driver Aadhaar",
    [TicketDocument.VEHICLE_OWNER_AADHAAR]: "Vehicle Owner Aadhaar",
    [TicketDocument.LORRY_RECEIPT]: "Lorry Receipt",
    [TicketDocument.GOODS_INVOICE]: "Goods Invoice"
};

export enum TicketStatusFilterValue {
    ALL = "",
    PENDING = 0,
    ACCEPTED = 1,
    PhysicalVerification = 3,
    FosVerified = 5,
    REJECTED = 999
}

export enum SubscriptionPlan {
    SHIPPER = 1,
    TRUCKER = 2,
    TRANSPORTER = 3
}

export const SubscriptionPlanText = {
    [SubscriptionPlan.SHIPPER]: "Shipper",
    [SubscriptionPlan.TRUCKER]: "Trucker",
    [SubscriptionPlan.TRANSPORTER]: "Transporter"
};

export enum TicketCallMeBackStatus {
    ALL = "All",
    NEW = "New",
    CLOSE = "Closed"
}

export const TableColumnName = {
    dateTime: {
        name: "dateTime",
        displayName: "Date & Time"
    },
    userProfile: {
        name: "userProfile",
        displayName: "User Profile"
    },
    status: {
        name: "status",
        displayName: "Status"
    },
    billNumber: {
        name: "billNumber",
        displayName: "E-way Bill Number"
    },
    lorryNumber: {
        name: "lorryNumber",
        displayName: "Lorry Number"
    },
    documentStatus: {
        name: "documentStatus",
        displayName: "Documents Status"
    },
    viewDocument: {
        name: "viewDocument",
        displayName: "View Documents"
    },
    actionNdRemarks: {
        name: "actionNdRemarks",
        displayName: "Action & Remarks"
    },
    reason: {
        name: "reason",
        displayName: "Reason"
    },
    refundRequestDateAndTime: {
        name: "dateTime",
        displayName: "Date & Time"
    },
    refundRequestedBy: {
        name: "refundRequestedBy",
        displayName: "Requested By"
    },
    refundRequestedFrom: {
        name: "refundRequestedFrom",
        displayName: "Requested From"
    },
    refundDetails: {
        name: "refundDetails",
        displayName: "Details"
    },
    refundStatusAndAction: {
        name: "refundStatusAndAction",
        displayName: "Updates"
    },
    vasLeadDetails: {
        name: "vasLeadDetails",
        displayName: "Details"
    },
    actionSlot: {
        name: "actionSlot",
        displayName: "action"
    },
    updatedBy: {
        name: "updatedBy",
        displayName: "Updated By"
    },
    source: {
        displayName: "Source",
        name: "source"
    },
    remarks: {
        displayName: "Remarks",
        name: "remarks"
    },
    misc: {
        displayName: "Misc",
        name: "misc"
    }
};

export enum LoadTicketType {
    VERIFICATION = 20,
    FULFILLMENT = 21,
    // VERIFIED_2 = 22,
    POST_PLACEMENT = 23
}

export const LoadTicketTypeToNameMap = {
    [LoadTicketType.VERIFICATION]: ScreenId.loadVerification,
    [LoadTicketType.FULFILLMENT]: ScreenId.loadFulfillment,
    [LoadTicketType.POST_PLACEMENT]: ScreenId.lostPostPlacement
};
export const LoadTicketNameToTypeMap = {
    [ScreenId.loadVerification]: LoadTicketType.VERIFICATION,
    [ScreenId.loadFulfillment]: LoadTicketType.FULFILLMENT,
    [ScreenId.lostPostPlacement]: LoadTicketType.POST_PLACEMENT
};

export const DispositionSourceScreen = {
    LOAD_LIST: "load-list",
    LORRY_LIST: "lorry-list",
    TSO_LOAD: "tso-load",
    TSO_LORRY: "tso-lorry"
};

const CancelLoadBidDInstantOptionNameWithValue = {
    "Vahak coin": "false",
    "Same source account": "true"
};

export const CancelLoadBidDInstantOptionList = Object.entries(CancelLoadBidDInstantOptionNameWithValue).map(
    ([key, value]) =>
        generateSelectOption(
            key,
            value as typeof CancelLoadBidDInstantOptionNameWithValue[keyof typeof CancelLoadBidDInstantOptionNameWithValue]
        )
);
