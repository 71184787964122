import React from "react";

//Style
import Styles from "./UserNameWithBadge.module.scss";

//Components
import AvatarImage from "../utils/avatar-image/AvatarImage";
import Typography from "@vahak/core-ui/dist/components/Typography";
import Flex from "@vahak/core-ui/dist/layout/Flex";

//Icons
import BankVerifiedIcon from "@vahak/core/dist/icons/Rupee-Green.svg";
import VerifiedIcon from "@vahak/core/dist/icons/verified-tick.svg";
import UserRating from "./user-rating/UserRating";
import EllipsisText, { EllipsisTextProps } from "../EllipsisText/EllipsisText";
import MembershipTag from "@vahak/core/dist/icons/VahakMember.svg";

interface UserNameWithBadgeProps {
    companyNameId?: string;
    companyLogo: string;
    companyLogoSize?: number;
    name: string;
    nameEllipsisLimit?: number;
    isBankVerified: boolean;
    isVerified: boolean;
    isAadhaarVerified: boolean;
    isGstVerified: boolean;
    isPanVerified: boolean;
    isMember: boolean;
    rating?: number;
    companyNameToolTipDirection?: EllipsisTextProps["tooltipDirection"];
}

const UserNameWithBadge = ({
    companyLogo,
    companyLogoSize,
    name,
    isBankVerified,
    isVerified,
    rating,
    companyNameId,
    isAadhaarVerified,
    isGstVerified,
    isPanVerified,
    isMember,
    nameEllipsisLimit = 12,
    companyNameToolTipDirection = "bottom"
}: UserNameWithBadgeProps) => {
    return (
        <Flex className={Styles.main} alignItems="center" gap={10}>
            <AvatarImage
                imageUrl={companyLogo}
                firstName={name}
                {...(companyLogoSize
                    ? {
                          size: "custom",
                          imageSize: companyLogoSize
                      }
                    : {
                          size: "s"
                      })}
            />
            <Flex alignItems="center" gap={3}>
                <div>
                    <Typography weight="semibold" size="sm">
                        <EllipsisText
                            id={companyNameId}
                            text={name}
                            limit={nameEllipsisLimit}
                            tooltipDirection={companyNameToolTipDirection}
                        />
                    </Typography>
                </div>
                {isBankVerified ? <BankVerifiedIcon /> : <></>}
                {(isVerified || isAadhaarVerified || isGstVerified || isPanVerified) && !isMember ? (
                    <VerifiedIcon />
                ) : (
                    <></>
                )}
                {isMember ? <MembershipTag /> : <></>}
                {rating ? <UserRating rating={rating} /> : <></>}
            </Flex>
        </Flex>
    );
};

export default UserNameWithBadge;
