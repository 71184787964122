import React, { useContext, useState } from "react";
import classnames from "classnames";

import { useQueryClient } from "react-query";

import Styles from "./AttachLorry.module.scss";

import dynamic from "next/dynamic";
const InfiniteScroll = dynamic(() => import("react-infinite-scroll-component"));

//Services
import { useGetAllLorriesInfiniteListV2 } from "../../services/lorry/useGetAllLorryDetailsV2/useGetAllLorryDetailsV2";
import { AppContext } from "@vahak/core/dist/app-context";
import {
    API_REQUEST_TYPE,
    getLorryDetailsByType,
    LORRY_BODY_TYPE_MAP,
    LORRY_CAPACITY_UNIT_TYPES,
    LORRY_CATEGORIES_ENUM,
    LORRY_STATUS,
    NEW_LORRY_TYPES,
    STATE_LIST
} from "@vahak/core/dist/constants";
import ModalOrBottomSheetWrapper from "@vahak/core-ui/dist/components/ModalOrBottomSheetWrapper";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import Typography from "@vahak/core-ui/dist/components/Typography";
import { ModalCrossIcon } from "@vahak/core-ui/dist/components/Modal/Modal";
import AppImg from "../utils/AppImg";
import LineDivider from "@vahak/core-ui/dist/components/LineDivider";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import Radio from "@vahak/core-ui/dist/components/Radio";
import Button from "@vahak/core-ui/dist/components/Button";

import PlusIcon from "@vahak/core/dist/standard-icons/plus-circle-white.svg";
import { useRouter } from "next/router";
import { LORRY_POSTING_FORM_LOCATION_STATE } from "../posting/lorry-posting/helpers/constants";
import {
    initialLoadCreationFormValues,
    initialLorryCreationFormValues,
    LORRY_CREATION_FORM_IDS,
    lorryCreationFormFieldNames,
    lorryCreationFormFieldsTypes
} from "../posting/helpers/constants";
import useValidateUserStatus from "../../hooks/useValidateUserStatus";
import { generataMaskUrlWithParams } from "../../methods/generataMaskUrlWithParams";
import { LorryCardV2, LorryListResponseData } from "../../services/lorry/commonType/type";
import { lorryFormValidations } from "../posting/helpers/validations/lorryFormValidations";
import { toast } from "@vahak/core/dist/components/toast/toast";
import { useToggleModalWithLocalState } from "@vahak/core-ui/dist/hooks";
import LorryReactivateModal from "../dashboard/list-wrapper/reactivate-load-lorry/lorry/LorryReactivateModal";
import { PlaceSearchResult } from "@vahak/core/dist/custom-types";
import { useReactivateLorry } from "@vahak/core/dist/_services";
import { POSTING_FORM_MODAL_ORIGINATION_PAGE, useLoadLorryPosting } from "../../context/load-lorry-posting";
import { getJoinedStringFromArray } from "@vahak/core-ui/dist/methods";
import { removeSubstringWithParenthesis } from "../../methods/removeSubstringWithParenthesis";
import useAttachLorriesAndBids from "../../services/useAttachLorriesAndBids";

import AttachLorryIcon from "@vahak/core/dist/standard-icons/attach-lorry-icon.svg";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
import Skeleton from "@vahak/core/dist/components/skeleton/Skeleton";
import queryNames from "../../services/queryNames";

interface AttachLorryProps {
    isOpen: boolean;
    toggleModal: (isLorryAttached: boolean) => void;
    bidId: number;
    lorryTypeId?: number;
    loadQuantity?: number;
    lorrySize?: number;
    lorryTyre?: number;
}

export const AttachLorryCardShimmer = () => {
    return (
        <>
            <Flex className={Styles.cardWrapper} gap={10}>
                <div className={Styles.imageWrapper}>
                    <Skeleton />
                </div>
                <Flex justifyContent="space-between" alignItems="center" style={{ width: "100%" }}>
                    <Flex flexDirection="column" gap={5} justifyContent="center">
                        <Typography weight="medium" size="md" mSize="m">
                            <Skeleton width={100} />
                        </Typography>
                        <Typography size={"sm"} mSize="s" color={COLORS.GREY_500} weight="medium">
                            <Skeleton width={250} />
                        </Typography>
                    </Flex>
                </Flex>
            </Flex>
            <div style={{ margin: "16px 0" }}>
                <LineDivider styleType="solid" linearGradient={true} linearGradientHeight={1} />
            </div>
        </>
    );
};

export interface AttachLorryCardProps {
    lorry?: LorryCardV2;
    isSelected?: boolean;
    onSelect: () => Promise<void> | void;
}
export const AttachLorryCard = ({ lorry, isSelected, onSelect }: AttachLorryCardProps) => {
    const capacityUnitMapping = (lorryType: number, capacity: number) => {
        if (lorryType === LORRY_CATEGORIES_ENUM.TANKER) {
            return `${capacity} ${capacity > 1 ? "Kilo Litres" : LORRY_CAPACITY_UNIT_TYPES.KILO_LITRE}`;
        } else {
            return `${capacity} ${capacity > 1 ? "Tonnes" : LORRY_CAPACITY_UNIT_TYPES.TONNE}`;
        }
    };

    const getLorryIcon = (lorryType: number) => {
        return NEW_LORRY_TYPES.find((l) => l.id === lorryType)?.iconRightV2;
    };
    return (
        <>
            <Flex
                className={Styles.cardWrapper}
                gap={10}
                onClick={async (e) => {
                    e.stopPropagation();
                    await onSelect();
                }}
            >
                <div className={Styles.imageWrapper}>
                    <AppImg
                        width={53}
                        height={42}
                        src={String(getLorryIcon(Number(lorry?.lorry_detail?.type_value)))}
                    />
                    <div
                        className={classnames(
                            Styles.status,
                            lorry?.lorry_detail?.status === LORRY_STATUS.IN_ACTIVE ? Styles.expired : ""
                        )}
                    >
                        <Typography size="s" mSize="xs" textTransform="uppercase" weight="medium">
                            {lorry?.lorry_detail?.status === LORRY_STATUS.IN_ACTIVE ? "Expired" : "Active"}
                        </Typography>
                    </div>
                </div>
                <Flex justifyContent="space-between" alignItems="center" style={{ width: "100%" }}>
                    <Flex flexDirection="column" gap={5} justifyContent="center">
                        <Typography weight="medium" size="md" mSize="m">
                            {lorry?.lorry_detail?.number}
                        </Typography>
                        <Typography size={"sm"} mSize="s" color={COLORS.GREY_500} weight="medium">
                            {lorry?.lorry_detail?.lorry_type} |{" "}
                            {LORRY_BODY_TYPE_MAP[lorry?.lorry_detail?.body_type ?? 0]} |{" "}
                            {capacityUnitMapping(
                                Number(lorry?.lorry_detail?.type_value),
                                Number(lorry?.lorry_detail?.capacity)
                            )}
                            {lorry?.lorry_detail?.size
                                ? " | " +
                                  lorry?.lorry_detail?.size +
                                  (lorry?.lorry_detail?.size_text ? lorry?.lorry_detail?.size_text : "ft")
                                : ""}
                        </Typography>
                    </Flex>

                    <Radio
                        id={String(lorry?.lorry_detail?.id)}
                        label=""
                        value={Number(lorry?.lorry_detail?.id)}
                        name={"lorry"}
                        onChange={() => {}}
                        checked={isSelected}
                    />
                </Flex>
            </Flex>
            <div style={{ margin: "16px 0" }}>
                <LineDivider styleType="solid" linearGradient={true} linearGradientHeight={1} />
            </div>
        </>
    );
};

const AttachLorry = ({
    isOpen,
    bidId,
    lorryTypeId,
    toggleModal,
    loadQuantity = 0,
    lorrySize = 0,
    lorryTyre = 0
}: AttachLorryProps) => {
    const { customerCompanyId, companyId } = useContext(AppContext);

    const router = useRouter();

    const queryClient = useQueryClient();

    const { mutateAsync: reactivateLorry } = useReactivateLorry();

    const [selectedLorry, setSelectedLorry] = useState<LorryCardV2>();
    const [data, setData] = useState<LorryListResponseData>();

    const _selectedLorryCategory = getLorryDetailsByType(lorryTypeId);

    const {
        isFetching: isFetchingLorryQuery,
        refetch,
        hasNextPage,
        fetchNextPage
    } = useGetAllLorriesInfiniteListV2(
        {
            companyId: Number(customerCompanyId || companyId),
            preloads: ["company_detail"],
            limit: 20,
            type: [String(lorryTypeId)],
            status: [LORRY_STATUS.ACTIVE, LORRY_STATUS.IN_ACTIVE],
            filter_out_phantom_lorry: true,
            request_type: API_REQUEST_TYPE.ELASTIC_SEARCH,
            ...(loadQuantity! && {
                capacity: {
                    min: loadQuantity - 1,
                    max: Math.ceil(loadQuantity + loadQuantity / 2)
                }
            }),
            ...(lorrySize! && {
                size: {
                    min: lorrySize,
                    max: lorrySize + lorrySize * 2
                }
            }),
            ...(lorryTyre &&
                lorryTypeId &&
                _selectedLorryCategory?.tyreWithCapacity && {
                    lorry_tyre: {
                        min: lorryTyre,
                        max: _selectedLorryCategory.tyreWithCapacity[_selectedLorryCategory.tyreWithCapacity.length - 1]
                            .id
                    }
                })
        },
        {
            refetchOnWindowFocus: false,
            retry: 0,
            enabled: isOpen,
            onError(err) {
                console.log(err, "---");
            },
            onSuccess(data) {
                setData(data?.[0]);
            }
        }
    );

    const { ValidateLorryPosting } = useValidateUserStatus();

    const { updateLoadLorryPostingData } = useLoadLorryPosting();

    const handleClickAttachLorry = () => {
        router.query[LORRY_POSTING_FORM_LOCATION_STATE.PARAM_NAME] = LORRY_CREATION_FORM_IDS.VEHICLE_NUMBER;

        /**
         * This will check for user verification status and opens aadhaar and pan modal or membership upgrade modal
         */
        ValidateLorryPosting?.(() => {
            //Callback function

            updateLoadLorryPostingData({
                isModalOpen: true,
                type: "lorry",
                category: "post",
                originationPage: POSTING_FORM_MODAL_ORIGINATION_PAGE.DASHBOARD,
                onSuccess: (data) => {
                    setSelectedLorry(undefined);
                    setTimeout(() => {
                        refetch();
                    }, 800);
                },
                onFailure: () => {
                    setSelectedLorry(undefined);
                },
                skipSuccessScreen: true
            });
            router.push({ query: router.query }, generataMaskUrlWithParams(router.query), {
                shallow: true
            });
        });
    };

    const { mutate: attachLorries } = useAttachLorriesAndBids();

    const handleCardClick = async (lorryData: LorryCardV2) => {
        if (lorryData.lorry_detail?.status === LORRY_STATUS.ACTIVE) {
            attachLorries(
                { attachlorries: [{ bid_id: bidId, lorry_id: lorryData.lorry_detail.id }] },
                {
                    onSuccess: (data) => {
                        if (data.data.results[0].status != 200) {
                            toast.error(data.data.results[0].message);
                            toggleModal(false);
                        } else {
                            toggleModal(true);
                            toast.success("Lorry attached successfully");
                        }
                        setTimeout(() => {
                            queryClient.refetchQueries([queryNames.myLorryBids]);
                        }, 500);
                    },
                    onError: () => {
                        toggleModal(false);
                        toast.error("Sorry! Couldn't attach the lorries");
                    }
                }
            );
            return;
        }

        const { lorry_detail } = lorryData;
        const lorryNumberSchema = lorryFormValidations[LORRY_CREATION_FORM_IDS.VEHICLE_NUMBER];
        const lorryNumberData = {
            [lorryCreationFormFieldNames.lorryNum]: lorry_detail?.number
        };

        if (!(await lorryNumberSchema.isValid(lorryNumberData))) {
            return toast.error("Invalid lorry number");
        }

        // validate and switch b/w edit modal or current location

        const dataToValidate = {
            [lorryCreationFormFieldNames.lorryType]: lorry_detail?.type_value,
            [lorryCreationFormFieldNames.lorryCapacity]: lorry_detail?.capacity,
            [lorryCreationFormFieldNames.lorryTyres]: lorry_detail?.total_tyres,
            [lorryCreationFormFieldNames.lorrySize]: lorry_detail?.size,
            [lorryCreationFormFieldNames.sizeText]: lorry_detail?.size_text,
            [lorryCreationFormFieldNames.lorryBodyType]: lorry_detail?.body_type
        };

        const schema = lorryFormValidations[LORRY_CREATION_FORM_IDS.VEHICLE_TYPE];
        schema
            .validate(dataToValidate)
            .then((valid) => {
                ValidateLorryPosting?.(() => toggleReactivateModal());
            })
            .catch((err) => {
                console.error(`ReactivateLoadLorry:`, err);
                ValidateLorryPosting?.(() => {
                    updateLoadLorryPostingData({
                        isModalOpen: true,
                        type: "lorry",
                        category: "edit",
                        ...(lorryData?.lorry_detail?.id && {
                            data: {
                                id: lorryData.lorry_detail.id,
                                values: getEditLorryData(lorryData)
                            }
                        }),
                        originationPage: POSTING_FORM_MODAL_ORIGINATION_PAGE.DASHBOARD,
                        onSuccess: (data) => {
                            setSelectedLorry(undefined);
                            setTimeout(() => {
                                refetch();
                            }, 800);
                        },
                        onFailure: () => {
                            setSelectedLorry(undefined);
                        }
                    });
                    router.query[LORRY_POSTING_FORM_LOCATION_STATE.PARAM_NAME] = LORRY_CREATION_FORM_IDS.VEHICLE_INFO;

                    router.push(router, generataMaskUrlWithParams(router.query), {
                        shallow: true
                    });
                });
            });
    };

    const handleActivation = async (place: PlaceSearchResult) => {
        const currentPlaceID = place?.place_id ?? "";
        reactivateLorry(
            { lorryId: Number(selectedLorry?.lorry_detail?.id), currentPlaceID },
            {
                onSuccess: (res) => {
                    toggleReactivateModal();
                    // toast.success("Lorry reactivated successfully.");
                    attachLorries(
                        { attachlorries: [{ bid_id: bidId, lorry_id: Number(selectedLorry?.lorry_detail?.id) }] },
                        {
                            onSuccess: (data) => {
                                if (data.data.results[0].status != 200) {
                                    toast.error(data.data.results[0].message);
                                } else {
                                    toast.success("Lorry attached successfully");
                                }
                                toggleModal(true);
                                setTimeout(() => {
                                    queryClient.refetchQueries([queryNames.myLorryBids]);
                                }, 500);
                            },
                            onError: () => {
                                toggleModal(false);
                                toast.error("Sorry! Couldn't attach the lorries");
                            }
                        }
                    );
                },
                onError: (res) => {
                    toggleReactivateModal();
                    toast.error(res.message);
                }
            }
        );
    };

    const { isModalOpen: isReactivateModalOpen, toggleModal: toggleReactivateModal } =
        useToggleModalWithLocalState(false);

    const isMobileScreen = useMediaQuery({ queryType: "mobile" });

    return (
        <>
            <ModalOrBottomSheetWrapper
                isOpen={isOpen}
                modalSize="fit"
                renderTitle={
                    <Flex className={Styles.titleWrapper} justifyContent="space-between">
                        <Typography size="md" weight="semibold">
                            Attach lorry
                        </Typography>
                        <Flex gap={20}>
                            <Button
                                type="button"
                                fillType="text"
                                disabled={isFetchingLorryQuery}
                                onClick={() => refetch()}
                            >
                                <Typography size="xxl" lineHeight={"0.7"}>
                                    &#10227;
                                </Typography>
                            </Button>
                            <ModalCrossIcon onClick={() => toggleModal(false)} />
                        </Flex>
                    </Flex>
                }
                borderRadius={16}
                disableSheetSwipe
                fullScreenSheet
            >
                <div className={Styles.container}>
                    <Flex flexDirection="column" className={Styles.main} id="scrollableDiv">
                        <InfiniteScroll
                            dataLength={data?.lorry_cards?.length ?? 0}
                            next={fetchNextPage}
                            loader={""}
                            hasMore={!!hasNextPage}
                            scrollableTarget="scrollableDiv"
                        >
                            {data?.lorry_cards.map((lorry) => {
                                return (
                                    <AttachLorryCard
                                        key={lorry?.lorry_detail?.id}
                                        lorry={lorry}
                                        isSelected={lorry?.lorry_detail?.id === selectedLorry?.lorry_detail?.id}
                                        onSelect={async () => {
                                            setSelectedLorry(lorry);
                                            await handleCardClick(lorry);
                                        }}
                                    />
                                );
                            })}

                            {isFetchingLorryQuery
                                ? Array(isMobileScreen ? 4 : 3)
                                      .fill(0)
                                      .map((_, ix) => {
                                          return <AttachLorryCardShimmer key={ix} />;
                                      })
                                : !data?.lorry_cards?.length && (
                                      <Flex
                                          flexDirection="column"
                                          justifyContent="center"
                                          alignItems="center"
                                          style={{ height: isMobileScreen ? "80vh" : "400px" }}
                                          gap={20}
                                      >
                                          <AttachLorryIcon />
                                          <Flex style={{ maxWidth: "250px" }}>
                                              <Typography
                                                  weight="semibold"
                                                  size="md"
                                                  color={COLORS.GREY_600}
                                                  align="center"
                                              >
                                                  No matching lorries, attach a new lorry to continue
                                              </Typography>
                                          </Flex>
                                      </Flex>
                                  )}
                        </InfiniteScroll>
                    </Flex>
                    <div className={Styles.btnWrapper}>
                        <Button
                            startIcon={<PlusIcon />}
                            disabled={isFetchingLorryQuery}
                            blockBtn
                            isRounded
                            onClick={handleClickAttachLorry}
                        >
                            Attach new vehicle
                        </Button>
                    </div>
                </div>
            </ModalOrBottomSheetWrapper>

            {/* Lorry activation */}
            {isReactivateModalOpen && (
                <div onClick={(e) => e.stopPropagation()}>
                    <LorryReactivateModal
                        isOpen={isReactivateModalOpen}
                        onToggle={() => {
                            setSelectedLorry(undefined);
                            toggleReactivateModal();
                        }}
                        onAction={handleActivation}
                    />
                </div>
            )}
        </>
    );
};

export default AttachLorry;

export const getEditLorryData = (data: LorryCardV2): lorryCreationFormFieldsTypes => {
    if (!data.lorry_detail) {
        return {
            ...initialLoadCreationFormValues,
            ...initialLorryCreationFormValues
        };
    }
    const stateData = STATE_LIST.find((state) => state.name === data?.lorry_detail?.current_state);
    return {
        ...initialLoadCreationFormValues,
        lorryType: data.lorry_detail.type_value,
        isLorryCapacityRequired: true,
        lorryCapacity: data.lorry_detail.capacity,
        lorryBodyType: data.lorry_detail.body_type,
        lorrySize: data.lorry_detail.size,
        lorryTyres: data.lorry_detail.total_tyres,
        sizeText: data.lorry_detail.size_text,
        lorryNum: data.lorry_detail.number,
        routeIds: data.lorry_detail.routes,
        rcDocument: undefined,
        status: data.lorry_detail.status,
        currentCityText: getJoinedStringFromArray([
            removeSubstringWithParenthesis(data.lorry_detail.current_city.split(",")[0] ?? ""),
            stateData?.name
        ]),
        currentCityOption: undefined,
        currentCityPlaceId: ""
    };
};
