import React, { ComponentProps, useMemo } from "react";
import Styles from "./Disclaimer.module.scss";

import Flex from "@vahak/core-ui/dist/layout/Flex";
import Button from "@vahak/core-ui/dist/components/Button";
import WarningIcon from "@vahak/core/dist/standard-icons/warning.svg";
import HighlighterIcon from "@vahak/core/dist/icons/load-verification-highlighter.svg";
import Lottie from "react-lottie";
import DepositRefundJson from "../../../../../assets/lottie/deposit_refund.json";
import Typography from "@vahak/core-ui/dist/components/Typography";
import AppLink from "../../../../utils/app-link/AppLink";
import { getPriceString } from "@vahak/core-ui/dist/methods";
import BulletStarIcon from "@vahak/core/dist/standard-icons/bullet-star.svg";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import CoinIcon from "@vahak/core/dist/standard-icons/coin.svg";
import { useDynamicConfig } from "../../../../../context/config-context";

export const getStarBulletList = (list: React.ReactNode[]) => {
    return (
        <ul className={Styles.list}>
            {list.map((item, ix) => (
                <li key={ix}>
                    <BulletStarIcon />
                    <Typography weight="medium" size="sm" color={COLORS.GREY_700}>
                        {item}
                    </Typography>
                </li>
            ))}
        </ul>
    );
};
const lottieAnimationDefaultOptions = {
    loop: true,
    autoplay: true,
    animationData: DepositRefundJson,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
} as ComponentProps<typeof Lottie>["options"];

interface DisclaimerProps {
    onClickPay?: () => void;
    onClickLater?: () => void;
    showFooterDisclaimer?: boolean;
    disabled?: boolean;
    verificationAmount: number;
}

export const DisclaimerHighlightedAction = ({
    onClickLater,
    onClickPay,
    showFooterDisclaimer,
    disabled,
    verificationAmount = 0
}: DisclaimerProps) => {
    const config = useDynamicConfig();
    const { load_verification_booking } = config ?? {};
    return (
        <>
            <div className={Styles.verificationAction}>
                <div className={Styles.row1}>
                    <HighlighterIcon />
                    <div className={Styles.content}>
                        <Typography weight="bold">Book vehicle by 5x speed</Typography>
                        <Typography size="sm">
                            by paying {getPriceString(verificationAmount)} refundable deposit
                        </Typography>
                    </div>
                </div>
                <div className={Styles.row2}>
                    <div className={Styles.ctaRow}>
                        {!!onClickLater && (
                            <Button
                                fillType="outlined"
                                color="success"
                                isRounded
                                width={160}
                                mSize="s"
                                onClick={onClickLater}
                            >
                                Pay Later
                            </Button>
                        )}
                        <Button color="success" isRounded blockBtn mSize="s" onClick={onClickPay} disabled={disabled}>
                            Pay {getPriceString(verificationAmount)}/-
                        </Button>
                    </div>
                    {!!showFooterDisclaimer && (
                        <Typography size="s" lineHeight={1.3} as="p" weight="medium">
                            ** Not confirming your load (By paying {getPriceString(verificationAmount)}) even after
                            getting lorry confirmation will lead to the loss of {<CoinIcon />}
                            {load_verification_booking?.coins_penalty || ""} coins from your balance.
                        </Typography>
                    )}
                </div>
            </div>
        </>
    );
};
const Disclaimer = (props: DisclaimerProps) => {
    const config = useDynamicConfig();
    const { load_verification_booking } = config ?? {};

    const listSlot = useMemo(() => {
        return getStarBulletList([
            <>
                Click Pay {getPriceString(load_verification_booking?.verification_amount ?? 0)}/-, to pay the deposit
                and post your load, increasing your chances of securing a trip
            </>,
            <>
                Click Pay Later, to post your load and pay after vehicle confirmation. Note: If the vehicle is confirmed
                within {load_verification_booking?.time_in_mins} mins and payment is not done from your side, then
                <CoinIcon />
                {load_verification_booking?.coins_penalty || ""} coins will be deducted from your balance
            </>,
            <>If the vehicle is not confirmed then the deposit will be refunded back to you</>
        ]);
    }, []);
    return (
        <div className={Styles.disclaimer}>
            <div>
                <Flex>
                    <Lottie
                        options={lottieAnimationDefaultOptions}
                        style={{
                            width: "auto",
                            height: 200,
                            marginTop: -50
                        }}
                    />
                </Flex>
                <Flex gap={5} alignItems="end">
                    <WarningIcon />
                    <Typography size="md" weight="semibold">
                        Disclaimer
                    </Typography>
                </Flex>
                {listSlot}
                <span style={{ marginLeft: 28 }}>
                    <AppLink target="_blank" href={"/tapngo-terms-and-conditions"}>
                        <Typography underline size="s" color={COLORS.BLUE} weight="medium">
                            Terms & Conditions
                        </Typography>
                    </AppLink>
                </span>
            </div>
            <DisclaimerHighlightedAction {...props} />
        </div>
    );
};

export default Disclaimer;
