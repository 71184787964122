import { createContext, useReducer, useState } from "react";

const initialBidFormConfig: any = {
    load: { open: false, reset: false, id: null },
    lorry: { open: false, reset: false, id: null },
    loadPreferredAmount: { open: false },
    attachLorry: { open: false }
};
const BiddingFormContext = createContext(initialBidFormConfig);

// Define actions
const BID_ACTIONS = {
    LOAD: "LOAD_BID",
    LORRY: "LORRY_BID",
    LOAD_PREFERRED_AMT: "PREFERRED_AMOUNT",
    ATTACH_LORRY: "ATTACH_LORRY",
    RESET: "RESET"
};

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case BID_ACTIONS.LOAD:
            return { ...state, load: action.payload };
        case BID_ACTIONS.LORRY:
            return { ...state, lorry: action.payload };

        case BID_ACTIONS.LOAD_PREFERRED_AMT:
            return { ...state, loadPreferredAmount: action.payload };
        case BID_ACTIONS.ATTACH_LORRY:
            return { ...state, attachLorry: action.payload };
        case BID_ACTIONS.RESET:
            return initialBidFormConfig;
        default:
            return state;
    }
};

const BiddingFormProvider = ({ children }: any) => {
    const [bidFormConfig, dispatchBid] = useReducer(reducer, initialBidFormConfig);
    return <BiddingFormContext.Provider value={{ bidFormConfig, dispatchBid }}>{children}</BiddingFormContext.Provider>;
};

export { BiddingFormProvider, BiddingFormContext, BID_ACTIONS };
