import { QueryNames } from "@vahak/core/dist/_services/hooks/queryNames";

import API from "@vahak/core/dist/_services/const";
import { useVahakMutationQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";
import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";
import { VASServices } from "../../constants/vas";
import { BidType } from "../bid/usePostLoadBid";
import { ApiErrorResponseType } from "@vahak/core/dist/custom-types/api-error-response-type";

interface Data {
    razorpay_order_id?: string;
    order_id?: string;
    cf_token?: string;
    notify_url?: string;
    order_amount?: number;
    rzp_payment_link?: string;
    payee_app_version?: string;
}

interface PaymentResponse {
    data: Data;
}

interface VasOrderRequest {
    vas_type: VASServices;
    plan_id: number;
    reference_id?: number;
    coupon_id?: number;
}

interface PaymentRequest {
    companyId: number;
    redeemCashback: boolean;
    referenceId?: number;
    vasOrders?: VasOrderRequest[];
    paymentMode?: PaymentMode;
    bookingAmountOrderRequest?: BookingAmountOrderRequest[];
}

export enum PaymentMode {
    CLIENT = 1,
    ADMIN = 2
}
interface PaymentParams {
    company_id: number;
    redeem_cashback: boolean;
    payment_mode: PaymentMode;
    vas_order_request?: VasOrderRequest[];
    booking_amount_order_request?: BookingAmountOrderRequest[];
}

export const useGeneratePayment = () => {
    const { POST } = useVahakHttpClient();

    function generatePayment(data: PaymentRequest) {
        const payload: PaymentParams = {
            payment_mode: data.paymentMode ?? PaymentMode.CLIENT,
            company_id: data.companyId,
            redeem_cashback: data.redeemCashback,
            vas_order_request: data.vasOrders,
            booking_amount_order_request: data?.bookingAmountOrderRequest
        };
        return POST<PaymentParams, PaymentResponse>(API.RAZORPAY_PAYMENT_LINK, payload);
    }

    return useVahakMutationQuery<PaymentResponse, ApiErrorResponseType, PaymentRequest>(
        [QueryNames.generatePayment],
        generatePayment,
        {
            onSettled: () => {}
        }
    );
};

export interface BookingAmountOrderRequest {
    amount?: number;
    load_id?: number;
    bid_id?: number;
    bid_type?: BidType;
    booking_for?: BookingForMap;
}

export enum BookingForMap {
    TAP_N_GO = 1,
    SPOT = 2,
    CASHBACK_DUE = 10
}
