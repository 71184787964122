import React from "react";
import Styles from "./SourceDestination.module.scss";
import classnames from "classnames";

//Components
import Typography from "@vahak/core-ui/dist/components/Typography";

//Assets
import SourceIcon from "@vahak/core/dist/standard-icons/source.svg";
import DestinationIcon from "@vahak/core/dist/standard-icons/destination.svg";
// Constants
import COLORS from "@vahak/core-ui/dist/constants/colors";
import FontSize from "@vahak/core-ui/dist/constants/fontSize";
import Chip from "@vahak/core-ui/dist/components/Chip";

interface SourceDestinationProps {
    id?: string;
    source?: string;
    destination?: string;
    sourceCity?: string;
    sourceState?: string;
    destCity?: string;
    destState?: string;
    distanceInKm?: number;
    fontSize?: keyof Pick<typeof FontSize, "sm" | "s">;
    ellipses?: boolean;
}

const SourceDestination = ({
    id,
    destination,
    source,
    destCity,
    destState,
    sourceCity,
    sourceState,
    distanceInKm,
    fontSize = "sm",
    ellipses = false
}: SourceDestinationProps) => {
    // const src = source || (sourceCity || sourceState ? [sourceCity, sourceState].filter((v) => !!v).join(", ") : " ");
    // const dest = destination || (destCity || destState ? [destCity, destState].filter((v) => !!v).join(", ") : " ");
    return (
        <div className={Styles.main}>
            <div className={Styles.locations}>
                <div className={Styles.location}>
                    <div className={Styles.icon}>
                        <SourceIcon />
                    </div>
                    <div className={classnames(Styles.info, { [Styles.ellipses]: ellipses })} id={`${id}--source`}>
                        <Typography weight="medium" size={fontSize} color={COLORS.GREY_900}>
                            {source || sourceCity}
                            {sourceState ? `, ` : ""}
                        </Typography>
                        {sourceState ? (
                            <Typography weight="medium" size={fontSize} color={COLORS.GREY_500}>
                                {sourceState}
                            </Typography>
                        ) : undefined}
                    </div>
                </div>
                <div className={Styles.location} id={`${id}--destination`}>
                    <div className={Styles.icon}>
                        <DestinationIcon />
                    </div>
                    <div className={classnames(Styles.info, ellipses ? Styles.ellipses : "")}>
                        <Typography weight="medium" size={fontSize} color={COLORS.GREY_900}>
                            {destination || destCity}
                            {destState ? `, ` : ""}
                        </Typography>
                        {destState ? (
                            <Typography weight="medium" size={fontSize} color={COLORS.GREY_500}>
                                {destState}
                            </Typography>
                        ) : undefined}
                    </div>
                </div>
            </div>
            {!!distanceInKm ? (
                <Chip weight="medium" size="auto" color={"grey"} filled id={`${id}--kilometer`} radius={50}>
                    {distanceInKm} km
                </Chip>
            ) : undefined}
        </div>
    );
};

export default SourceDestination;
