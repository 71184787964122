import React, { useEffect, useRef } from "react";
import Styles from "./AssuredPaymentConfirmation.module.scss";
import { FormikProps } from "formik";
// Components
import HowAssuredLoadWorksSteps from "./HowAssuredLoadWorksSteps";
import LineDivider from "@vahak/core-ui/dist/components/LineDivider";
import Typography from "@vahak/core-ui/dist/components/Typography";
import { VerticalFlex } from "@vahak/core-ui/dist/layout/Flex";
import Button from "@vahak/core-ui/dist/components/Button";
import ExpectedPrice from "../../../../posting-form/common/expected-price/ExpectedPrice";
// Constants
import COLORS from "@vahak/core-ui/dist/constants/colors";
import { IEventTrackerProp } from "../../../../../types/events";
import { LoadMarketCard } from "../../../../../services/load/useGetLoadMarketV2/type";
import { BiddingFormValues } from "../../common/type";
import { LoadBidFormFieldNames } from "../../common/fieldNames";
import { GA4EventNames } from "@vahak/core/dist/constants/event-names";
import { IDs } from "@vahak/core/dist/constants/automation";
import { NoSuccessErrorHandler } from "@vahak/core/dist/constants/api";
// Methods
import { getPriceString } from "@vahak/core-ui/dist/methods/common";
import { removeNonNumeric } from "@vahak/core-ui/dist/methods/string";
// Hooks
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
import { useGetPriceRecommendation } from "../../../../../services/posting/useGetPriceRecommendation";
// Assets
import CheckIcon from "@vahak/core/dist/icons/tick_white.svg";
import CrossIcon from "@vahak/core/dist/icons/cross.svg";
import { scrollToElement } from "@vahak/core/dist/methods/scrollToElement";

interface AssuredPaymentConfirmationProps extends IEventTrackerProp {
    onClickBid?: () => void;
    bidPrice?: number;
    additionalCharges?: number;
    closeBidForm?: () => void;
    disableBtn?: boolean;
    companyId?: number;
    isTapNgoLoad?: boolean;
    isSpotLoad?: boolean;
    isPaidBookingRequired?: boolean;
    formikConfig?: FormikProps<BiddingFormValues>;
    loadData?: LoadMarketCard;
    bookingAmount?: number;
    enablePriceSuggestion?: boolean;
}

const AssuredPaymentConfirmation = ({
    onClickBid,
    bidPrice,
    closeBidForm,
    disableBtn,
    additionalCharges,
    companyId,
    trackEvent,
    isTapNgoLoad,
    isSpotLoad,
    isPaidBookingRequired,
    formikConfig,
    loadData,
    bookingAmount = 0,
    enablePriceSuggestion
}: AssuredPaymentConfirmationProps) => {
    const isMobileScreen = useMediaQuery({ queryType: "mobile" });
    const autoFocusSubmitRef = useRef<HTMLDivElement>(null);

    const { data: recommendedPrice } = useGetPriceRecommendation(
        {
            destination_id: loadData?.load_detail.destination_id,
            lorry_type_id: loadData?.load_detail.lorry_type_id,
            source_id: loadData?.load_detail.source_id,
            lorry_capacity: loadData?.load_detail.quantity
        },
        {
            ...NoSuccessErrorHandler,
            enabled: Boolean(companyId) && isSpotLoad && !formikConfig?.values?.maxExpectedAmount
        }
    );

    const handleRejectAction = () => {
        closeBidForm?.();
        trackEvent?.(GA4EventNames.MARKET_PLACE["bid_ignored"]);
    };

    const handleExpectedAmount: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        e.target.value = removeNonNumeric(e.target.value).slice(0, 7);
        formikConfig?.setFieldValue(e.target.name, e.target.value);
    };

    useEffect(() => {
        scrollToElement(autoFocusSubmitRef);
    }, []);

    return (
        <VerticalFlex gap={isMobileScreen ? 10 : 30}>
            <HowAssuredLoadWorksSteps
                type="load-bid"
                bookingAmount={bookingAmount}
                isTapNgoLoad={isTapNgoLoad}
                isSpotLoad={isSpotLoad}
                isPaidBookingRequired={isPaidBookingRequired}
            />
            <LineDivider styleType="solid" color={COLORS.GREY_200} />

            <VerticalFlex gap={10} alignItems="center">
                {isTapNgoLoad && (
                    <>
                        <Typography size="md" mSize="sm">
                            Do you agree with the Fixed Price?
                        </Typography>
                        <VerticalFlex
                            {...(isMobileScreen && {
                                alignItems: "center"
                            })}
                        >
                            <Typography size="l" mSize="md" weight="bold">
                                {getPriceString(bidPrice)}/-
                            </Typography>
                            {!!additionalCharges && (
                                <Typography lineHeight={1} size="sm" mSize="xs" color={COLORS.GREY_500}>
                                    (Additional charges{" "}
                                    <Typography weight="semibold">{getPriceString(additionalCharges)}</Typography>)
                                    <div style={{ marginBottom: 20 }} />
                                </Typography>
                            )}
                        </VerticalFlex>
                    </>
                )}
                {isSpotLoad && (
                    <>
                        <ExpectedPrice
                            expectedAmount={formikConfig?.values.expectedAmount}
                            expectedAmountFieldName={LoadBidFormFieldNames.expectedAmount}
                            suggestionMax={recommendedPrice?.maximum_price}
                            suggestionMin={recommendedPrice?.minimum_price}
                            onFocus={() => trackEvent?.(GA4EventNames.MARKET_PLACE["bid_amount_entered"])}
                            onChange={handleExpectedAmount}
                            quantity={loadData?.load_detail?.quantity}
                            id={IDs.bidForm.bidPrice}
                            errorMsg={formikConfig?.errors.expectedAmount}
                            isRequired={true}
                            autoFocus={!isMobileScreen}
                            additionalAmount={loadData?.load_detail?.meta_data?.loading_charges_detail?.charges}
                        />
                        <br />
                    </>
                )}

                <div className={Styles.buttonWrapper}>
                    {isTapNgoLoad && enablePriceSuggestion && (
                        <Button color="danger" onClick={handleRejectAction} isRounded>
                            <span className={Styles.buttonIconWrapper}>
                                <CrossIcon />
                            </span>
                            No
                        </Button>
                    )}
                    <Button
                        color="success"
                        onClick={onClickBid}
                        disabled={disableBtn}
                        isRounded
                        disableColor="secondary"
                        ref={autoFocusSubmitRef as any}
                    >
                        <span className={Styles.buttonIconWrapper}>
                            <CheckIcon />
                        </span>
                        {isPaidBookingRequired && bookingAmount ? `Pay ${getPriceString(bookingAmount)}` : `Yes`}
                    </Button>
                </div>
            </VerticalFlex>
        </VerticalFlex>
    );
};

export default AssuredPaymentConfirmation;
