import { QueryNames } from "@vahak/core/dist/_services/hooks/queryNames";

import API from "@vahak/core/dist/_services/const";
import { ClientHookOptions, useVahakMutationQuery, useVahakQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";

import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";
import { generateUrlParams } from "@vahak/core-ui/dist/methods/generateUrlParams";
import { generateQueryString } from "@vahak/core/dist/methods/generateQueryString";
import { ApiErrorResponseType } from "@vahak/core/dist/custom-types/api-error-response-type";

type BookingAmount = {
    load_owner: number;
    lorry_owner: number;
};

interface BookingAmountResponse {
    data: {
        tap_n_go: BookingAmount;
        spot_load: BookingAmount;
    };
}

interface GetBookingAmountParams {
    amount?: number;
    companyId: number;
    loadCompanyId?: number;
    lorryCompanyId?: number;
}

export const useGetBookingAmount = (
    params: GetBookingAmountParams,
    options: ClientHookOptions<BookingAmountResponse, unknown, BookingAmountResponse["data"]>
) => {
    const { GET } = useVahakHttpClient();

    const method = () => {
        const payload = {
            bid_amount: params?.amount,
            c_i: params?.companyId,
            ld_i: params?.loadCompanyId,
            lr_i: params?.lorryCompanyId
        };

        const urlString = generateQueryString(payload);

        return GET<BookingAmountResponse>(`${API.GET_BOOKING_AMOUNT}${urlString}`);
    };

    return useVahakQuery<BookingAmountResponse, unknown, BookingAmountResponse["data"]>(
        [QueryNames.bid.bookingAmount, params],
        method,
        {
            ...options,
            select: (data) => {
                return data?.data ?? [];
            }
        }
    );
};

export const useGetBookingAmountMutation = () => {
    const { GET } = useVahakHttpClient();

    const method = (params: GetBookingAmountParams) => {
        const payload = {
            bid_amount: params?.amount,
            c_i: params?.companyId,
            ld_i: params?.loadCompanyId,
            lr_i: params?.lorryCompanyId
        };

        const urlString = generateQueryString(payload);
        return GET<BookingAmountResponse>(`${API.GET_BOOKING_AMOUNT}&${urlString}`);
    };

    return useVahakMutationQuery<BookingAmountResponse, ApiErrorResponseType, GetBookingAmountParams>(
        [QueryNames.searchLocation],
        method
    );
};
