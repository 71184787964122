import { CommonProps, GenericObject } from "@vahak/core-ui/dist/types";
import { createContext, useState, useContext } from "react";
import { BookingAmountOrderRequest } from "../services/vas/useGeneratePayment";

export interface IPaymentProvider extends CommonProps {}

export const PaymentProvider = ({ children }: IPaymentProvider) => {
    const [paymentData, setPaymentData] = useState<IPaymentContextData>({});

    const updatePaymentData = (details: IPaymentContextData) => {
        setPaymentData((prevDetails) => ({ ...prevDetails, ...details }));
    };

    return <PaymentContext.Provider value={{ paymentData, updatePaymentData }}>{children}</PaymentContext.Provider>;
};

export interface QueriesToInvalidate {
    success?: string[];
    error?: string[];
    back?: string[];
}

export interface IPaymentContextData {
    type?: "assured-booking-amount" | "assured-security-deposit";
    allowCoinUse?: boolean;
    bidBookingInfo?: BookingAmountOrderRequest;
    metaData?: Record<"penalties", any>;
    title?: string;
    onClickBack?: () => void;
    onSuccess?: () => void;
    postSuccess?: () => void;
    onFailure?: () => void;
    postFailure?: () => void;
    onSettle?: () => void;
    queriesToInvalidate?: QueriesToInvalidate;
    srcUrl?: string;
}

export interface IPaymentContext {
    paymentData?: IPaymentContextData;
    updatePaymentData?: (data: IPaymentContextData) => void;
}

const PaymentContext = createContext<IPaymentContext>({});

export const usePayment = () => useContext(PaymentContext);
