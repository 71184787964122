import React from "react";
import dynamic from "next/dynamic";

//Context
import { useRouter } from "next/router";
import { useLoadLorryPosting } from "../context/load-lorry-posting";
import StandaloneLorryPostingFormModal from "./posting/lorry-posting/standalone-lorry-posting-form-modal/StandaloneLorryPostingFormModal";
import { useDetailsPageContext } from "../DetailsPageContextProvider";

//Components
const StandaloneLoadPostingFormModal = dynamic(
    () => import("./posting/load-posting/standalone-load-posting-form-modal/StandaloneLoadPostingFormModal"),
    { ssr: false }
);

const LiveLorryLocationModalWrapper = dynamic(
    () =>
        import(
            "../../nextjs/components/load-lorry-details/components/intransit-lorry-location/LiveLorryLocationModalWrapper/LiveLorryLocationModalWrapper"
        ),
    {
        ssr: false
    }
);

export interface CommonGlobalComponentsWrapperProps {}

const CommonGlobalComponentsWrapper = ({}: CommonGlobalComponentsWrapperProps) => {
    const router = useRouter();
    const { loadLorryData } = useLoadLorryPosting();
    const { type, data, isModalOpen, originationPage } = loadLorryData ?? {};
    let loadData = type === "load" ? data : undefined;
    let lorryData = type === "lorry" ? data : undefined;

    const { detailsPageData } = useDetailsPageContext();

    return (
        <>
            {isModalOpen && type === "load" && (
                <StandaloneLoadPostingFormModal editLoadData={loadData} originationPage={originationPage} />
            )}
            {isModalOpen && type === "lorry" && (
                <StandaloneLorryPostingFormModal editLorryData={lorryData} originationPage={originationPage} />
            )}

            {detailsPageData?.openLorryLocationModal && <LiveLorryLocationModalWrapper />}
        </>
    );
};

export default CommonGlobalComponentsWrapper;
