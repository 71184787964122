import React, { useContext } from "react";
import dynamic from "next/dynamic";

//Style
import Styles from "./LoadBidFormNew.module.scss";

//Components
import BidForm from "./bid-form/BidForm";
import { BiddingFormContext } from "../../../BiddingFormContextProvider";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import { IEventTrackerProp } from "../../../types/events";
const ModalOrBottomSheetWrapper = dynamic(() => import("@vahak/core-ui/dist/components/ModalOrBottomSheetWrapper"), {
    ssr: false
});
import PreferredAmountForm from "./bid-form/assured-load/preferred-amount-form/PreferredAmountForm";
import { LoadMarketCard } from "../../../services/load/useGetLoadMarketV2/type";
import AttachLorry from "../../attach-lorry/AtttachLorry";

export interface lorryDetailsAlreadySelected {
    isLorryAvailable: boolean;
    lorryId: number;
    lorryCapacity: number;
    lorryNum: string;
}
interface LoadBidFormNewProps {
    lorryAlreadySelected?: lorryDetailsAlreadySelected;
    onSuccessLoadBid?: () => void;
    trackEvent?: IEventTrackerProp["trackEvent"];
}

const LoadBidFormNew = ({ lorryAlreadySelected, onSuccessLoadBid, trackEvent }: LoadBidFormNewProps) => {
    const { bidFormConfig } = useContext(BiddingFormContext);
    const loadDataForAttachLorry = bidFormConfig.attachLorry.loadData as LoadMarketCard;

    return (
        <>
            {bidFormConfig.load.open && (
                <ModalOrBottomSheetWrapper
                    isOpen={bidFormConfig.load.open}
                    onToggle={() => {}}
                    showHeader={false}
                    paddedModal={false}
                    modalSize="fit"
                    sheetBackgroundColor={COLORS.GREY_100}
                    paddedSheet={false}
                    sheetBodyHeight={"90vh"}
                    sheetBodyMaxHeight={"90vh"}
                    disableSheetSwipe
                >
                    <BidForm
                        trackEvent={trackEvent}
                        onSuccessLoadBid={onSuccessLoadBid}
                        lorryAlreadySelected={lorryAlreadySelected}
                    />
                </ModalOrBottomSheetWrapper>
            )}
            {bidFormConfig?.loadPreferredAmount?.open &&
                bidFormConfig?.loadPreferredAmount?.id &&
                bidFormConfig?.loadPreferredAmount?.price && (
                    <PreferredAmountForm
                        onSubmit={bidFormConfig?.loadPreferredAmount?.onSubmit}
                        expectedPrice={bidFormConfig?.loadPreferredAmount?.price}
                        auctionPrice={bidFormConfig?.loadPreferredAmount?.auctionPrice}
                        quantity={bidFormConfig?.loadPreferredAmount?.quantity}
                        isOpen={bidFormConfig?.loadPreferredAmount?.open}
                    />
                )}

            {bidFormConfig?.attachLorry?.open && (
                <AttachLorry
                    isOpen
                    lorryTypeId={loadDataForAttachLorry?.load_detail?.lorry_type_id}
                    bidId={bidFormConfig?.attachLorry.bidId}
                    toggleModal={(success = false) => {
                        success ? bidFormConfig?.attachLorry?.onSuccess() : bidFormConfig?.attachLorry?.onError();
                        bidFormConfig?.attachLorry?.onResolve();
                    }}
                    lorrySize={loadDataForAttachLorry?.load_detail?.size}
                    lorryTyre={loadDataForAttachLorry?.load_detail?.total_tyres}
                    loadQuantity={loadDataForAttachLorry?.load_detail?.quantity}
                />
            )}
        </>
    );
};

export default LoadBidFormNew;
