import { MY_LOAD_LORRY_TABS } from "@vahak/core/dist/constants/tab";
import { ROUTES } from "@vahak/core/dist/constants/routeStrings";
import { GA4EventNames } from "@vahak/core/dist/constants/event-names";
import LoadListingDisabledIcon from "@vahak/core/dist/icons/load_listing_disabled.svg";
import LoadListingEnabledIcon from "@vahak/core/dist/icons/load_listing_white.svg";
import LorryListingActiveIcon from "@vahak/core/dist/icons/my_lorries_active.svg";
import LorryListingInactiveIcon from "@vahak/core/dist/icons/my_lorries_inactive.svg";
import PremiumLoadsDisabledIcon from "@vahak/core/dist/icons/premium_loads_disabled.svg";
import PremiumLoadsEnabledIcon from "@vahak/core/dist/icons/premium_loads_white.svg";
import MarketplaceNavActiveIcon from "@vahak/core/dist/icons/MarketplaceNavActiveIcon.svg";
import MarketplaceNavDeadIcon from "@vahak/core/dist/icons/MarketplaceNavDeadIcon.svg";
import PaymentsDisabledIcon from "@vahak/core/dist/icons/payments_disabled.svg";
import PaymentsEnabledIcon from "@vahak/core/dist/icons/payments_white.svg";
import HomeEnabledIcon from "@vahak/core/dist/icons/home-icon.svg";
import HomeDisabledIcon from "@vahak/core/dist/icons/home-icon-disabled.svg";
import TransporterDirectoryEnabled from "@vahak/core/dist/icons/transporter-directory-active.svg";
import TransporterDirectoryDisabled from "@vahak/core/dist/icons/transporter-directory-disabled.svg";

//Mobile icon
import HomeIconMobEnabled from "@vahak/core/dist/icons/vahak-home-icon-mob-active.svg";
import HomeIconMobDisabled from "@vahak/core/dist/icons/vahak-home-icon-mob-disable.svg";
import LoadListingEnabledIconMob from "@vahak/core/dist/icons/bottom-nav-load-icon-mob-active.svg";
import LoadListingDisabledIconMob from "@vahak/core/dist/icons/bottom-nav-load-icon-mob-disabled.svg";
import LorryListingEnabledIconMob from "@vahak/core/dist/icons/bottom-nav-lorry-icon-mob-active.svg";
import LorryListingDisabledIconMob from "@vahak/core/dist/icons/bottom-nav-lorry-icon-mob-disabled.svg";
import MarketplaceEnabledIconMob from "@vahak/core/dist/icons/bottom-nav-marketplace-icon-mob-active.svg";
import MarketplaceDisabledIconMob from "@vahak/core/dist/icons/bottom-nav-marketplace-icon-mob-disabled.svg";
import NetworkEnabledIconMob from "@vahak/core/dist/icons/bottom-nav-network-icon-mob-active.svg";
import NetworkDisabledIconMob from "@vahak/core/dist/icons/bottom-nav-network-icon-mob-disabled.svg";

import { useRouter } from "next/router";
import { NavItemsType } from "./dashboard-navbar/DashboardNavbar";
import ListingsNavDeadIcon from "@vahak/core/dist/icons/ListingsNavDeadIcon.svg";
import ListingsNavActiveIcon from "@vahak/core/dist/icons/ListingsNavActiveIcon.svg";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
import { CompanyServiceType } from "@vahak/core/dist/_services/auth.service";
import { useFeatureByServiceType } from "../../hooks/useFeatureByServiceType";

export const NavBarName = {
    PREMIUM_LOADS: "premium-loads"
};

export const NavBarMenuItems = (navbarName?: string, mktType?: string): NavItemsType => {
    const router = useRouter();
    const mobileScreen = useMediaQuery({ queryType: "mobile" });

    const loadLorryIconStyle = { transform: "scale(0.9)" };
    const marketPlaceIconStyle = { transform: "scale(1.2)", marginRight: 4, marginLeft: 3 };

    const { ShowLoadPost, ShowLorryPost, showLoadFeatures, showLorryFeatures } = useFeatureByServiceType();

    let navElementArray = [];

    if (ShowLoadPost)
        navElementArray.push({
            id: "myLoads",
            icon: mobileScreen ? (
                <LoadListingDisabledIconMob />
            ) : (
                <LoadListingDisabledIcon style={loadLorryIconStyle} />
            ),
            iconActive: mobileScreen ? (
                <LoadListingEnabledIconMob />
            ) : (
                <LoadListingEnabledIcon style={loadLorryIconStyle} />
            ),
            label: "My Loads",
            pathName:
                mktType && router.asPath.includes("my-loads")
                    ? router.asPath.includes("bids")
                        ? ROUTES.myLoadsMyLorries.getUrl("loads", MY_LOAD_LORRY_TABS.BIDS)
                        : ROUTES.myLoadsMyLorries.getUrl("loads", MY_LOAD_LORRY_TABS.LISTING)
                    : ROUTES.myLoadsMyLorries.getUrl("loads", MY_LOAD_LORRY_TABS.LISTING),
            clientAppEventName: GA4EventNames.SIDE_NAV.my_load_icon_clicked
        });

    if (ShowLorryPost)
        navElementArray.push({
            id: "myLories",
            icon: mobileScreen ? (
                <LorryListingDisabledIconMob />
            ) : (
                <LorryListingInactiveIcon style={loadLorryIconStyle} />
            ),
            iconActive: mobileScreen ? (
                <LorryListingEnabledIconMob />
            ) : (
                <LorryListingActiveIcon style={loadLorryIconStyle} />
            ),
            label: "My Lorries",
            pathName:
                mktType && router.asPath.includes("my-lorries")
                    ? router.asPath.includes("bids")
                        ? ROUTES.myLoadsMyLorries.getUrl("lorries", MY_LOAD_LORRY_TABS.BIDS)
                        : ROUTES.myLoadsMyLorries.getUrl("lorries", MY_LOAD_LORRY_TABS.LISTING)
                    : ROUTES.myLoadsMyLorries.getUrl("lorries", MY_LOAD_LORRY_TABS.LISTING),
            clientAppEventName: GA4EventNames.SIDE_NAV.my_lorry_icon_clicked
        });

    navElementArray.push(
        {
            id: "home",
            icon: mobileScreen ? <HomeIconMobDisabled /> : <HomeDisabledIcon style={loadLorryIconStyle} />,
            iconActive: mobileScreen ? <HomeIconMobEnabled /> : <HomeEnabledIcon style={loadLorryIconStyle} />,
            label: "Home",
            pathName: ROUTES.dashboard.getUrl(),
            clientAppEventName: GA4EventNames.SIDE_NAV.homepage_icon_clicked
        },
        ...(showLoadFeatures || showLorryFeatures
            ? [
                  {
                      id: "marketplace",
                      icon: mobileScreen ? (
                          <MarketplaceDisabledIconMob />
                      ) : (
                          <MarketplaceNavDeadIcon style={marketPlaceIconStyle} />
                      ),
                      iconActive: mobileScreen ? (
                          <MarketplaceEnabledIconMob />
                      ) : (
                          <MarketplaceNavActiveIcon style={marketPlaceIconStyle} />
                      ),
                      label: "Market",
                      pathName:
                          mktType && showLoadFeatures && showLorryFeatures
                              ? `/marketplace/${
                                    router.asPath.includes("load") || router.asPath.includes("loads") ? "load" : "lorry"
                                }`
                              : !showLoadFeatures && showLorryFeatures
                              ? ROUTES.marketplace.getUrl("lorry")
                              : ROUTES.marketplace.getUrl("load"),
                      clientAppEventName: GA4EventNames.SIDE_NAV.marketplace_icon_clicked
                  }
              ]
            : []),

        {
            id: "transporter",
            icon: mobileScreen ? <NetworkDisabledIconMob /> : <TransporterDirectoryDisabled />,
            iconActive: mobileScreen ? <NetworkEnabledIconMob /> : <TransporterDirectoryEnabled />,
            label: "Network",
            pathName: ROUTES.transporters.getUrl(),
            clientAppEventName: GA4EventNames.SIDE_NAV.transport_directory_clicked
        }
    );
    return navElementArray;
};
