import React, { useContext, useState } from "react";
import { FormikProps } from "formik";

//Style
import Styles from "./BiddingSection.module.scss";

//Components
import Typography from "@vahak/core-ui/dist/components/Typography";
import ExpectedPrice from "../../../../posting-form/common/expected-price/ExpectedPrice";
import { LoadMarketCard } from "../../../../../services/load/useGetLoadMarketV2/type";
import { BiddingFormValues } from "../../common/type";
import { GA4EventNames } from "@vahak/core/dist/constants/event-names";

import { IDs } from "@vahak/core/dist/constants/automation";

import { removeNonNumeric } from "@vahak/core-ui/dist/methods/string";

import Input from "@vahak/core-ui/dist/components/Input";
import LineDivider from "@vahak/core-ui/dist/components/LineDivider";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import { LoadBidFormFieldNames } from "../../common/fieldNames";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
import { useGetPriceRecommendation } from "../../../../../services/posting/useGetPriceRecommendation";
import { NoSuccessErrorHandler } from "@vahak/core/dist/constants/api";
import { AppContext } from "@vahak/core/dist/app-context";

interface BiddingSectionProps {
    loadData: LoadMarketCard;
    formikConfig: FormikProps<BiddingFormValues>;
    trackEvent?: (name: string) => void;
}

const BiddingSection = ({ loadData, formikConfig, trackEvent }: BiddingSectionProps) => {
    const { companyId } = useContext(AppContext);
    const { load_detail } = loadData;

    const [lorryNum, setLorryNum] = useState(formikConfig.values.lorryNum.replace(/ /g, "").toUpperCase());

    const handleExpectedAmount: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        e.target.value = removeNonNumeric(e.target.value).slice(0, 7);
        formikConfig.handleChange("expectedAmount")(e);
        // scrollToElement(reportingTimeRef);
    };

    const onChangeLorryNum: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const val = e.target.value;
        setLorryNum(val.replace(/ /g, "").toUpperCase());
        const newVal = val.replace(/ /g, "").toUpperCase();
        formikConfig.setFieldValue(LoadBidFormFieldNames.lorryNum, newVal);
    };

    const isMobileScreen = useMediaQuery({ queryType: "mobile" });

    const { data: recommendedPrice } = useGetPriceRecommendation(
        {
            destination_id: loadData.load_detail.destination_id,
            lorry_type_id: loadData.load_detail.lorry_type_id,
            source_id: loadData.load_detail.source_id,
            lorry_capacity: loadData.load_detail.quantity
        },
        {
            ...NoSuccessErrorHandler,
            onSuccess(data) {},
            enabled: Boolean(companyId),
            retry: 0
        }
    );

    return (
        <Flex className={Styles.main} flexDirection="column" gap={isMobileScreen ? 6 : 20}>
            <div className={Styles.title}>
                <Typography weight="semibold">Enter your bid price</Typography>
            </div>

            <div className={Styles.expectedPriceWrapper}>
                <ExpectedPrice
                    expectedAmount={formikConfig.values.expectedAmount}
                    expectedAmountFieldName={LoadBidFormFieldNames.expectedAmount}
                    suggestionMax={recommendedPrice?.max_expected_price}
                    suggestionMin={recommendedPrice?.min_expected_price}
                    onFocus={() => trackEvent?.(GA4EventNames.MARKET_PLACE["bid_amount_entered"])}
                    onChange={handleExpectedAmount}
                    quantity={load_detail.quantity}
                    id={IDs.bidForm.bidPrice}
                    errorMsg={formikConfig.errors.expectedAmount}
                    isRequired={true}
                    autoFocus={!isMobileScreen}
                    additionalAmount={load_detail?.meta_data?.loading_charges_detail?.charges}
                />
            </div>

            <Typography size="sm" weight="medium">
                Bid price per tonne: ₹
                <Typography weight="semibold">
                    {formikConfig.values.expectedAmount
                        ? Intl.NumberFormat("en-IN").format(
                              Math.floor(formikConfig.values.expectedAmount / load_detail.quantity)
                          )
                        : "--"}
                </Typography>
            </Typography>

            {formikConfig.values.expectedAmount && !formikConfig?.values?.lorryNum ? (
                <>
                    {!isMobileScreen ? (
                        <LineDivider styleType="solid" color={COLORS.GREY_200} borderWidth={2} width="200px" />
                    ) : (
                        <div style={{ marginTop: "5px" }}></div>
                    )}

                    <Typography weight="semibold" color={COLORS.GREY_700} mSize="s">
                        Enter Vehicle Number (Optional)
                    </Typography>

                    <Input
                        id={IDs.bidForm.bidFormVehicleNumber}
                        placeholder="HR02BA9876"
                        value={lorryNum}
                        onChange={onChangeLorryNum}
                        name={LoadBidFormFieldNames.lorryNum}
                        type="text"
                        required
                        errorMsg={formikConfig.errors.lorryNum}
                        onBlur={formikConfig.handleBlur}
                    />
                </>
            ) : (
                <></>
            )}
        </Flex>
    );
};

export default BiddingSection;
