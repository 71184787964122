import React, { useEffect, useState } from "react";
import Styles from "./LoadPriceMeter.module.scss";
import RedIcon from "@vahak/core/dist/icons/price_indicator--red.svg";
import GreenIcon from "@vahak/core/dist/icons/price_indicator--green.svg";
import YellowIcon from "@vahak/core/dist/icons/price_indicator--yellow.svg";
import { clampNumber, getPriceString } from "@vahak/core-ui/dist/methods/common";
import Typography from "@vahak/core-ui/dist/components/Typography";

export interface LoadPriceMeterProps {
    min?: number;
    max?: number;
    current?: number;
    showAvgPriceLabel?: boolean;
}

const LoadPriceMeter = (props: LoadPriceMeterProps) => {
    const { current = 0, min = 0, max = 1, showAvgPriceLabel = false } = props;
    const priceSlabs = [min * 0.8, min, max, max * 1.2];

    const [width, setWidth] = useState(0);
    const [pointerPosition, setPointerPosition] = useState(0);

    const flex = [15, 18, 35, 32]; // Non-linear flex distribution
    const containerRef = React.useRef<any>(null);

    useEffect(() => {
        if (containerRef.current) {
            const containerWidth = containerRef.current.offsetWidth;
            setWidth(containerWidth - 32); // 16px padding on each side
        }
    }, []);

    useEffect(() => {
        if (width > 0) {
            setPointerPosition(calculatePointerPosition());
        }
    }, [width, current]);

    const calculatePointerPosition = () => {
        const perUnitWidth = width / 100;
        if (current > priceSlabs[2]) {
            return 75 * perUnitWidth; // Cap position if price exceeds highest slab
        }

        const initialMargin = flex.slice(0, index).reduce((sum, flexValue) => sum + flexValue * perUnitWidth, 0);

        const segmentWidth =
            (flex[index] * perUnitWidth) / (index > 0 ? priceSlabs[index] - priceSlabs[index - 1] : priceSlabs[index]);

        const position = initialMargin + (current - (index > 0 ? priceSlabs[index - 1] : 0)) * segmentWidth;

        return position > 16 ? position - 16 : position; // Adjust for pointer width
    };

    const determinePriceIndex = () => {
        if (current > priceSlabs[2]) return 3;
        if (current > priceSlabs[1]) return 2;
        if (current > 0 && current <= priceSlabs[0]) return 0;
        return 1;
    };
    const getCurrentStatus = () => {
        switch (index) {
            case 3: {
                return {
                    text: "Your price is as per market standards. You have a high chance of getting accepted.",
                    icon: <GreenIcon />
                };
            }
            case 2: {
                return {
                    text: "Given price is moderately low. Try to increase the price to have a high chance of getting bids.",
                    icon: <YellowIcon />
                };
            }
            case 1:
            case 0: {
                return {
                    text: "Price is too low. There is fewer chance of getting bids.",
                    icon: <RedIcon />
                };
            }
        }
    };

    const index = determinePriceIndex();
    const position = clampNumber(Math.floor((pointerPosition / width) * 100), 0, 75);
    const { text: statusText, icon: statusIcon } = getCurrentStatus() ?? {};

    return (
        <div className={Styles.main}>
            <div className={Styles.inputContainer} ref={containerRef}>
                <input type="range" className={Styles.sliderInput} value={position} disabled />
            </div>
            <div className={Styles.statusContainer}>
                <span>{statusIcon}</span>
                {statusText}
            </div>
            {showAvgPriceLabel && (
                <div>
                    <Typography size="s"> Suggested Price: {getPriceString(max)}</Typography>
                </div>
            )}
        </div>
    );
};

export default LoadPriceMeter;
