import { CommonProps, GenericObject } from "@vahak/core-ui/dist/types";
import { createContext, useState, useContext } from "react";
import { EditLoadTypes, EditLorryTypes } from "../components/posting/helpers/constants";
import Router from "next/router";
import { LOAD_POSTING_FORM_LOCATION_STATE } from "../components/posting/load-posting/helpers/constants";
import { LORRY_POSTING_FORM_LOCATION_STATE } from "../components/posting/lorry-posting/helpers/constants";

export enum POSTING_FORM_MODAL_ORIGINATION_PAGE {
    DASHBOARD,
    USER_PROFILE,
    MARKETPLACE,
    KAM_UPDATING_LORRY_ON_BULK_LOAD,
    UPDATE_VEHICLE_DETAILS_KAM,
    HOME,
    TSO_LORRY,
    SIDE_NAV,
    LORRY_BID_FORM,
    RC_VERIFICATION,
    TICKET_SYSTEM,
    TRANSPORTER_PROFILE
}

export interface ILoadLorryPostingProvider extends CommonProps {}

export const LoadLorryPostingProvider = ({ children }: ILoadLorryPostingProvider) => {
    const [loadLorryData, setLoadLorryPostingData] = useState<ILoadLorryPostingContextData>();

    const updateLoadLorryPostingData = (details: ILoadLorryPostingContextData) => {
        setLoadLorryPostingData(
            (prevDetails) => ({ ...prevDetails, ...details, firstPost: details?.firstPost } as any)
        );
    };
    const clearPostingData = () => {
        setLoadLorryPostingData(undefined);
    };

    const openLoadPostingModal = (step?: string) => {
        !!step &&
            Router.push(
                {
                    query: {
                        ...Router.query,
                        [LOAD_POSTING_FORM_LOCATION_STATE.PARAM_NAME]: step
                    }
                },
                undefined,
                {
                    shallow: true,
                    scroll: false
                }
            );
    };

    const openLorryPostingModal = (step?: string) => {
        !!step &&
            Router.push(
                {
                    query: {
                        ...Router.query,
                        [LORRY_POSTING_FORM_LOCATION_STATE.PARAM_NAME]: step
                    }
                },
                undefined,
                {
                    shallow: true,
                    scroll: false
                }
            );
    };

    return (
        <LoadLorryPostingContext.Provider
            value={{
                loadLorryData,
                updateLoadLorryPostingData,
                openLoadPostingModal,
                openLorryPostingModal,
                clearPostingData
            }}
        >
            {children}
        </LoadLorryPostingContext.Provider>
    );
};

type LoadResponse = {
    loadId?: number;
    data?: any;
};

export interface ILoadLorryPostingContextDataBase {
    type?: "load" | "lorry";
    onClose?: () => void | Promise<void>;
    onSuccess?: (response?: LoadResponse) => void | Promise<void>;
    // postSuccess?: (response?: LoadResponse) => void;
    onFailure?: (response?: any) => void | Promise<void>;
    // postFailure?: (response?: LoadResponse) => void;
    queriesToInvalidate?: {
        success?: string[];
        error?: string[];
        back?: string[];
    };
    submitCtaText?: string;
    skipSuccessScreen?: boolean;
    skipNotification?: boolean;
    isModalOpen?: boolean;
    isUpdateExpectedPriceModalOpen?: boolean;
    selectedLorryType?: any; // typeof NEW_LORRY_TYPES[number]
    originationPage: POSTING_FORM_MODAL_ORIGINATION_PAGE;
    metaData?: GenericObject;
}
export interface ILoadLPostingContextData
    extends ILoadLorryPostingContextDataBase,
        Pick<EditLoadTypes["values"], "isExpectedAmountMandatory"> {
    type: "load";
    category: "post" | "re-post" | "edit";
    data?: EditLoadTypes;
    selectedSrcText?: string;
    priceOptionList?: any[];
    firstPost?: boolean;
}
export interface ILorryPostingContextData extends ILoadLorryPostingContextDataBase {
    type: "lorry";
    category: "post" | "edit";
    data?: EditLorryTypes;
    firstPost?: boolean;
}

export type ILoadLorryPostingContextData = ILoadLPostingContextData | ILorryPostingContextData | undefined;

export interface ILoadLorryPostingContext {
    loadLorryData?: ILoadLorryPostingContextData;
    updateLoadLorryPostingData: (data: ILoadLorryPostingContextData) => void;
    openLoadPostingModal: (step?: string) => void;
    openLorryPostingModal: (step?: string) => void;
    clearPostingData: () => void;
}

const LoadLorryPostingContext = createContext<ILoadLorryPostingContext>({} as any);

export const useLoadLorryPosting = () => useContext(LoadLorryPostingContext);
