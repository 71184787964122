import Typography from "@vahak/core-ui/dist/components/Typography";
import Flex, { VerticalFlex } from "@vahak/core-ui/dist/layout/Flex";
import React from "react";
import Styles from "./HowAssuredLoadWorksSteps.module.scss";
import AppLink from "../../../../utils/app-link/AppLink";
import AssuredHelpDesk from "../../../../assured-load/helpdesk-wrapper/AssuredHelpDesk";
import { getPriceString } from "@vahak/core-ui/dist/methods";

interface HowAssuredLoadWorksStepsProps {
    type: "load-bid" | "lorry-bid" | "load-lorry-details";
    showHelp?: boolean;
    bookingAmount?: number;
    isTapNgoLoad?: boolean;
    isSpotLoad?: boolean;
    isPaidBookingRequired?: boolean;
}

type IListItem = {
    ix?: number;
    content: React.ReactNode;
};

const ListItem = ({ content, ix }: IListItem) => {
    return (
        <span className={Styles.listItem}>
            <span className={Styles.count}>{ix}</span>
            <span className={Styles.content}>{content}</span>
        </span>
    );
};

const getLoadBidList = (meta: Partial<HowAssuredLoadWorksStepsProps>) => {
    const { bookingAmount: amount = 0, isPaidBookingRequired, isSpotLoad, isTapNgoLoad } = meta ?? {};

    if (isTapNgoLoad) {
        return [
            {
                content: "This is a fixed rate load"
            },
            {
                content: (
                    <>
                        Either accept the price using the {amount ? `"Pay ${getPriceString(amount)}"` : `"Yes"`} or
                        enter your suggested price using the
                        <Typography weight="bold"> No </Typography>
                        button
                    </>
                )
            },
            {
                content: `Get trip confirmation within 30 minutes, or your payment will be fully refunded`
            }
        ];
    } else if (isSpotLoad) {
        return [
            {
                content: `This is a spot load`
            },
            {
                content: `Enter your price and pay  ${
                    amount ? getPriceString(amount) : "booking amount"
                } to instantly confirm your vehicle`
            },
            {
                content: `Get trip confirmation within 30 minutes, or your payment will be fully refunded`
            }
        ];
    } else return [];
};

const LoadLorryDetails = [
    "You accept the bid or the lorry person accepts your bid.",
    "You pay the security deposit within the given time showing your interest in this transaction.",
    "Lorry person too will pay the security deposit showing his interest.",
    "If both have paid the security deposit within the given time, your transaction will proceed to next phase.",
    "If the lorry person doesn’t show interest, your security deposit will be refunded within 24 hours and all of your older bids will be reactivated so that you can accept other bid."
];

const HowAssuredLoadWorksSteps = ({
    type,
    showHelp = true,
    bookingAmount = 0,
    isPaidBookingRequired,
    isSpotLoad,
    isTapNgoLoad
}: HowAssuredLoadWorksStepsProps) => {
    return (
        <div className={Styles.main}>
            <Flex justifyContent="space-between" alignItems="center">
                <Typography weight="semibold" size="md" mSize="m">
                    {isTapNgoLoad ? "How fixed rate load works?" : "How spot load works?"}
                </Typography>
                {showHelp && <AssuredHelpDesk callBackSrc="assured confirmation" />}
            </Flex>
            <VerticalFlex className={Styles.list}>
                {type === "load-bid" &&
                    getLoadBidList({
                        bookingAmount,
                        isPaidBookingRequired,
                        isSpotLoad,
                        isTapNgoLoad
                    }).map((item, ix) => (
                        <Typography weight="medium" key={ix}>
                            <ListItem content={item?.content} ix={ix + 1} key={ix} />
                        </Typography>
                    ))}

                {type === "load-lorry-details" &&
                    LoadLorryDetails.map((item, ix) => <ListItem content={item} ix={ix + 1} key={ix} />)}
            </VerticalFlex>
        </div>
    );
};

export default HowAssuredLoadWorksSteps;
