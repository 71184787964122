import React from "react";
import classnames from "classnames";

//Style
import Styles from "./UserRating.module.scss";

//Icon
import RatingStarIcon from "@vahak/core/dist/standard-icons/rating-star.svg";

//Components
import Typography from "@vahak/core-ui/dist/components/Typography";
import COLORS from "@vahak/core-ui/dist/constants/colors";

interface UserRatingProps {
    rating: number;
    flat?: boolean;
}

const UserRating = ({ rating, flat }: UserRatingProps) => {
    return (
        <div className={classnames(Styles.main, flat && Styles.flat)}>
            <RatingStarIcon />
            <Typography size="xs" mSize="xs" weight="semibold" color={COLORS.GREY_700}>
                {rating.toPrecision(2) || 0}
            </Typography>
        </div>
    );
};

export default UserRating;
